import React, { useEffect, useState } from "react";
import { getAllVediosWithout } from ".";
import { isAuthenticated } from "../../Auth/helper";
import LoggedInNavbar from "./loggedInNavbar";
import { useNavigate } from "react-router-dom";
import Header from "../../User/helper/Header";
import { LazyLoadImage } from "react-lazy-load-image-component";

const UserPlay = () => {
	const navigate = useNavigate();
	let [products, setAllProducts] = useState([]);
	let [mainCat, setMainCat] = useState([]);
	let [catThumbNail, setCatThumbNail] = useState([]);
	let [val, setVal] = useState("");

	const change = (event) => {
		setVal(event.target.value);
	};

	const filteredProducts = products.filter((product) => {
		return product.productName.toLowerCase().includes(val.toLowerCase());
	});

	const checkSignedIn = (id, musicid, item) => {
		console.log(id, musicid);
		const isSignedin =
			isAuthenticated() &&
			isAuthenticated().token &&
			isAuthenticated().user._id;
		if (isSignedin) {
			//
			navigate(`/player/${id}/${musicid}?way=${item}`);
		} else {
			navigate("/signin");
		}
	};

	useEffect(() => {
		console.log("This is useeffect");
		const fetchdata = async () => {
			try {
				console.log("rUnjal");
				await getAllVediosWithout()
					.then((x) => {
						console.log(x);

						const getsubCategory = x.map(
							(item) => item.subCategoryId
						);
						let uniqueObjArray = [
							...new Map(
								getsubCategory.map((item) => [
									item["subCategoryName"],
									item,
								])
							).values(),
						];
						console.log(uniqueObjArray);

						const mainCategory = uniqueObjArray.map(
							(item) => item.subCategoryName
						);
						const thumbArr = uniqueObjArray.map(
							(item) => item.subCategoryImageCDN
						);

						setMainCat(mainCategory);
						setCatThumbNail(thumbArr);
						setAllProducts(x);
					})
					.catch();
			} catch {}
		};

		fetchdata();
		//getActiveMusicArray()
	}, []);

	const showCategory = (id) => {
		const Video = "Videos";
		navigate(`/category/${Video}/${id}`);
	};

	return (
		<div>
			{isAuthenticated() &&
			isAuthenticated().user._id &&
			isAuthenticated().token ? (
				<LoggedInNavbar />
			) : (
				<Header />
			)}
			<br />

			<div className="container" style={{}}>
				<h2
					style={{
						color: "#E34607",
						lineHeight: "34px",
						padding: "20px",
					}}>
					Videos
				</h2>

				<div
					className=" d-flex flex-row flex-nowrap overflow-auto"
					style={{ gap: "20px", padding: "0 0 18px 0" }}>
					{mainCat.map((item, index) => {
						return (
							<div className="col-sm-2">
								<center>
									{" "}
									<div
										className="card"
										onClick={() => showCategory(item)}
										style={{
											width: "200px",
											cursor: "pointer",
										}}>
										<div className="card-body">
											{/* <h5 className="card-title" style={{ color: "#E34607" }}>
                        {item}
                      </h5> */}
											<LazyLoadImage
												src={catThumbNail[index]}
												alt={item}
												height="auto"
												width="100%"
												effect="blur"
											/>
										</div>
									</div>
								</center>
							</div>
						);
					})}
				</div>

				<br />

				<input
					className="accordian__search"
					onChange={change}
					value={val}
					type="search"
					placeholder="Search here | यहाँ टाइप करें"
				/>

				<br />
				<br />

				<div className="row">
					{filteredProducts.map((item, key) => {
						return (
							<div
								className="col-sm-4"
								style={{ padding: "13px" }}>
								<center>
									<div
										className="card"
										style={{
											flexDirection: "row",
											cursor: "pointer",
											backgroundColor: "#f5f5f5",
											height: "100px",
										}}
										onClick={() =>
											checkSignedIn(
												item._id,
												item.productCategory._id,
												item.productPathCDN
											)
										}>
										<LazyLoadImage
											style={{ width: "100px" }}
											src={item.thumbNailPathCDN}
											alt="gvg"
										/>
										<div className="card-body">
											<h6 className="card-title">
												{item.productName}
											</h6>
										</div>
									</div>
								</center>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default React.memo(UserPlay);
