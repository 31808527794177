import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./User/helper/Home";
import Signin from "./User/helper/Signin";
import UserPlay from "./Admin/helper/userPlay";
import MediaPlay from "./Admin/helper/player";
import AudioPlay from "./Admin/helper/audio";
import ProtectedRoutes from "./Auth/helper/protectedRoutes";
import UserProfile from "./Admin/helper/userProfile";
import AdminDashboard from "./Core/helper/adminDashboard";
import ShowImage from "./Admin/helper/showImage";
import MainPage from "./User/helper/MainPage";
import ShowDocument from "./Admin/helper/showDocument";
import FilterCategory from "./Admin/helper/categoryFilter";
import AudioPlayer from "./Admin/helper/audioPlayList";
import PlayAudios from "./Admin/helper/audio";
import DisplayData from "./Admin/helper/displayData";
import DisplayDocument from "./Admin/helper/displayDocument";
import NoPage from "./Admin/helper/NoPage";
import SpeechPlayList from "./Admin/helper/speechPlayList";
import SpeechPlay from "./Admin/helper/speech";
import SongPlayList from "./Admin/helper/songPlayList";
import SongPlay from "./Admin/helper/songs";
import UnderConstruction from "./User/helper/wip";
import SignUp from "./User/helper/Signup";
import AddVideo from "./Core/helper/mediaManagement/Videos/AddVideos";
import AddSpeech from "./Core/helper/mediaManagement/Speech/AddSpeech";
import AddSongs from "./Core/helper/mediaManagement/Songs/AddSongs";
import AddImages from "./Core/helper/mediaManagement/Images/AddImages";
import AddDocuments from "./Core/helper/mediaManagement/Documents/AddDocuments";
import AddAudios from "./Core/helper/mediaManagement/Audios/AddAudios";
import EditVideos from "./Core/helper/mediaManagement/Videos/editVideos";
import EditSongs from "./Core/helper/mediaManagement/Songs/editSongs";
import EditSpeech from "./Core/helper/mediaManagement/Speech/editSpeech";
import EditImages from "./Core/helper/mediaManagement/Images/editImage";
import EditDocuments from "./Core/helper/mediaManagement/Documents/editDocuments";
import LinkExpiry from "./User/helper/LinkExpiry";
import HKMbooks from "./User/helper/hkm-books";
import TestingPlayList from "./User/helper/testingPlayList";

function App() {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path="/mainpage" element={<Home />}></Route>
					<Route exact path="/signin" element={<Signin />}></Route>
					<Route exact path="/signup" element={<SignUp />}></Route>
					<Route exact path="/" element={<MainPage />}></Route>
					<Route
						path="audios"
						exact
						element={<AudioPlayer />}></Route>
					<Route path="vedios" exact element={<UserPlay />}></Route>
					<Route
						path="speech"
						exact
						element={<SpeechPlayList />}></Route>
					<Route
						path="songs"
						exact
						element={<SongPlayList />}></Route>
					<Route path="images" exact element={<ShowImage />}></Route>

					{/* new routes */}
					<Route
						path="/hkm-books"
						exact
						element={<HKMbooks />}></Route>

					<Route
						path="/testingPlayList"
						exact
						element={<TestingPlayList />}></Route>

					{/* New routte ends */}
					<Route
						path="/confirmpassword/:userId/:uid"
						exact
						element={<LinkExpiry />}></Route>

					<Route element={<ProtectedRoutes />}>
						<Route
							path="speechplayer/:userId/:musicId"
							exact
							element={<SpeechPlay />}></Route>
						<Route
							path="songplayer/:userId/:musicId"
							exact
							element={<SongPlay />}></Route>
						<Route
							path="document"
							exact
							element={<ShowDocument />}></Route>
						<Route
							path="displaydata/:userId/:musicId"
							exact
							element={<DisplayData />}></Route>
						<Route
							path="displaydocument"
							exact
							element={<DisplayDocument />}></Route>
						<Route
							path="category/:cat/:catName"
							element={<FilterCategory />}></Route>
						<Route
							path="userprofile"
							exact
							element={<UserProfile />}></Route>
						<Route
							path="player/:userId/:musicId"
							exact
							element={<MediaPlay />}></Route>
						<Route
							path="audioplayer/:userId/:musicId"
							exact
							element={<PlayAudios />}></Route>
						<Route
							path="adminDashboard"
							exact
							element={<AdminDashboard />}></Route>
						<Route
							path="adminDashboard/uploadvideos"
							exact
							element={<AddVideo />}></Route>
						<Route
							path="adminDashboard/uploadspeech"
							exact
							element={<AddSpeech />}></Route>
						<Route
							path="adminDashboard/uploadsongs"
							exact
							element={<AddSongs />}></Route>
						<Route
							path="adminDashboard/uploadimages"
							exact
							element={<AddImages />}></Route>
						<Route
							path="adminDashboard/uploaddocuments"
							exact
							element={<AddDocuments />}></Route>
						<Route
							path="adminDashboard/uploadaudios"
							exact
							element={<AddAudios />}></Route>

						<Route
							path="admindashboard/editvideos"
							exact
							element={<EditVideos />}></Route>
						<Route
							path="admindashboard/editsongs"
							exact
							element={<EditSongs />}></Route>
						<Route
							path="admindashboard/editspeech"
							exact
							element={<EditSpeech />}></Route>
						<Route
							path="admindashboard/editimage"
							exact
							element={<EditImages />}></Route>
						<Route
							path="admindashboard/editdocuments"
							exact
							element={<EditDocuments />}></Route>
					</Route>

					<Route path="*" exact element={<NoPage />}></Route>
					<Route
						path="/wip"
						exact
						element={<UnderConstruction />}></Route>
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;
