import { useState } from "react";
import Logo from "../../User/helper/logo.png";
import {
	MDBContainer,
	MDBNavbar,
	MDBNavbarBrand,
	MDBNavbarToggler,
	MDBIcon,
	MDBNavbarNav,
	MDBCollapse,
	MDBNavbarLink,
	MDBNavbarItem,
} from "mdb-react-ui-kit";

import { Link, useNavigate } from "react-router-dom";
import { API } from "../../backend";
const LoggedInNavbar = () => {
	const [showBasic, setShowBasic] = useState(false);
	let navi = useNavigate();
	const logout = async () => {
		await fetch(`${API}signout`, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});

		localStorage.removeItem("token");

		navi("/signin");
	};

	return (
		<div>
			<MDBNavbar light bgColor="light" style={{ overflow: "hidden" }}>
				<MDBContainer
					fluid
					style={{
						margin: "0 24px",
					}}>
					<Link to="/">
						<MDBNavbarBrand>
							<img src={Logo} alt="logo" height="30px"></img>
						</MDBNavbarBrand>
					</Link>
					<MDBNavbarToggler
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
						onClick={() => setShowBasic(!showBasic)}>
						<MDBIcon icon="bars" fas style={{ color: "#E34607" }} />
					</MDBNavbarToggler>
					<MDBCollapse navbar show={showBasic}>
						<MDBNavbarNav className="mr-auto mb-2 mb-lg-0">
							<Link to="/images">
								{" "}
								<MDBNavbarLink
									style={{
										margin: "16px 0 0 0 ",
									}}>
									{" "}
									<MDBNavbarItem>
										{" "}
										Photo Gallery फोटो गैलरी{" "}
									</MDBNavbarItem>
								</MDBNavbarLink>
							</Link>
							<Link to="/speech">
								{" "}
								<MDBNavbarLink>
									{" "}
									<MDBNavbarItem>
										{" "}
										Discourses प्रवचन{" "}
									</MDBNavbarItem>{" "}
								</MDBNavbarLink>
							</Link>
							<Link to="/songs">
								<MDBNavbarLink>
									<MDBNavbarItem>Songs भजन </MDBNavbarItem>
								</MDBNavbarLink>
							</Link>
							<Link to="/vedios">
								{" "}
								<MDBNavbarLink>
									{" "}
									<MDBNavbarItem>
										Videos विडियोज{" "}
									</MDBNavbarItem>
								</MDBNavbarLink>
							</Link>
							<Link to="/document">
								{" "}
								<MDBNavbarLink>
									{" "}
									<MDBNavbarItem>Ebooks </MDBNavbarItem>
								</MDBNavbarLink>
							</Link>
							<Link to="/">
								{" "}
								<MDBNavbarLink>
									{" "}
									<MDBNavbarItem>
										Hare Krishna Mandir, Pune{" "}
									</MDBNavbarItem>
								</MDBNavbarLink>
							</Link>
							{/* <Link to="/">
                {" "}
                <MDBNavbarLink>
                  {" "}
                  <MDBNavbarItem> Hare Krishna Mandir Trust </MDBNavbarItem>
                </MDBNavbarLink>
              </Link> */}
							<Link to="/wip">
								{" "}
								<MDBNavbarLink>
									{" "}
									<MDBNavbarItem>Contact us </MDBNavbarItem>
								</MDBNavbarLink>
							</Link>
						</MDBNavbarNav>
						<div
							className="d-flex input-group w-auto"
							style={{
								margin: "12px 0 18px 0",
							}}>
							<Link to="/userprofile">
								{" "}
								<i
									className="fa fa-user"
									style={{
										fontSize: "35px",
										margin: "0 12px",
										cursor: "pointer",
									}}
									aria-hidden="true"></i>{" "}
							</Link>
							<abbr title="logout">
								{" "}
								<i
									className="fa fa-power-off"
									onClick={logout}
									aria-hidden="true"
									style={{
										fontSize: "20px",
										marginRight: "7px",
										cursor: "pointer",
										margin: "12px",
									}}></i>{" "}
							</abbr>
						</div>
					</MDBCollapse>
				</MDBContainer>
			</MDBNavbar>
		</div>
	);
};

export default LoggedInNavbar;
