import { useState } from "react";
import { useEffect } from "react";
import { deletemediafile, getAllSongsAdmin, getAllSpeechAdmin, getAllVediosAdmin } from "../../..";
import LoggedInNavbar from "../../../../Admin/helper/loggedInNavbar";
import { isAuthenticated } from "../../../../Auth/helper";
import Modal from 'react-bootstrap/Modal'
import { getAllCategory, updatePoduct } from "../../../../Admin/helper";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import ReactPlayer from 'react-player/lazy';
import Search from 'react-search'

const EditSpeech=()=>{
    const [videos,setvideos]=useState([]);
    let [loading, setLoading] = useState(false);
    const [modalitem,setmodelitem]=useState([]);
    const [show, setShow] = useState(false);
    const [subCatgory,setSubCategory]=useState([])
    const [getallcat,setallcat]=useState([])
    const [showinput,showthumbnailInput]=useState(false)
    const [uploadThumbNailfile,setuploadThumbNailfile]=useState(null)
    const [uploadafile,setuploadfile]=useState(null)
    const [updateMedia,setMedia]=useState(false)


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);  
    const [uploadmedia, setupload]=useState({
       
        productName:"",
        subCategoryId:'0',
        description:null
    })
    const {productName,description,subCategoryId,thumbNailRemove}=uploadmedia
    
    useEffect( ()=>{
        const userdata= async()=>{

     
        const {token,user}=isAuthenticated();
   await  getAllSpeechAdmin(user._id,token).then(data=>{
      setvideos(data)
     }) 
     await getAllCategory(user._id,token).then((re)=>{
        console.log(re)
        setallcat(re.allCategory)
        setSubCategory(re.allsubCategory)
      }).catch((e)=>{
          console.log(e);
      })
   
    }
    userdata() 
    },[show,updateMedia])

    const deletemedia=async(id)=>{

        let cu=isAuthenticated();
     const {token,user}=isAuthenticated();
        await deletemediafile(user._id,token,id).then((re)=>{
            if(re.message){
                alert("Product deleted successfully");
            }
            setMedia(!updateMedia)
         
            
    
        }).catch(e=>{
            alert("Error in deleting media.");
            return false;
        })
    
    }
    const handleformfield=name=>e=>{
   
        console.log(name,e.target.value)
        setupload({...uploadmedia,[name]:e.target.value})
    }
 
     const handlesubmit=async(e)=>{
      
        e.preventDefault();
        console.log(uploadmedia)   
        console.log(uploadThumbNailfile);
        console.log(uploadafile) 
        if(!productName){
            alert("All fields are mandatory");
            return false
        }
        else
        {
         setLoading(!loading)
            var newform=new FormData();
 
        
 
         newform.append("myfile",uploadafile || null);
         newform.append("myfile1",uploadThumbNailfile || null);
         newform.append("productName",productName || null);
         newform.append("subCategoryId",subCategoryId)
         newform.append("description",description|| null)
          
          //let abc={"productName":productName,"categoryId":categoryId}
 let cur=isAuthenticated();
 console.log(newform)
  await updatePoduct(cur.user._id,cur.token,modalitem._id,modalitem.productCategory._id,newform).then((ret)=>{
  if(ret.message){
 setLoading(false)
   alert(ret.message);
  setupload({productName:"",
         
         categoryId:"0",
         subCategoryId:'0',
         description:""
 
  })
  setuploadfile(null)
  setuploadThumbNailfile(null)
 }
  if(ret.error){
   setLoading(!loading)
    alert("Error in processing request");
    return false;
  }
  }).catch((e)=>{
   setLoading(!loading)
    alert("Error in sending request");
    return false
  })
 
 
       }
 
   }

let items=[]

videos.map((item,key)=>{
    const constructobj={id:key,value:item?.productName}
    items.push(constructobj)

})

// function setSearchModal(item){
//     setmodelitem(videos[item[0]?.id])
//     console.log("this is triggered")
//      item && handleShow()
//       setupload({description:item?.description,subCategoryId:item?.subCategoryId?._id,productName:item?.productName
//       })
 
// }
    return(<>
    
    <LoggedInNavbar/>
    <br/>
    <Search items={items}
    placeholder='Search Media here'
   //  onItemsChanged={(item)=>setSearchModal(item)}
    

    
     />

<br />
    <table className="table table-striped" >
   <thead>
    <tr  style={{backgroundColor:"#FF1493"}}>
 
        <th>Media Name</th>
        <th>Media Type</th>
        <th>Delete</th>
        <th>Edit</th>
         
    </tr>
    </thead>
    <tbody style={{color:"black"}}>

    {
        
       
    videos.map((item,key)=>{
        return(
        <>
          <tr style={{color:"black"}}>
       <td  style={{color:"black"}}>{item.productName}</td>
       <td style={{color:"black"}}>{item.productCategory.categoryName}</td>
    
       <td style={{color:"black",cursor:"pointer"}}><i className="fa fa-trash" onClick={()=>deletemedia(item._id)} ></i>    </td>
       <td style={{color:"black",cursor:"pointer"}}><i className="fa fa-edit" onClick={()=>{setmodelitem(item)
  handleShow() 
  console.log(item)
  setupload({description:item.description,subCategoryId:item.subCategoryId._id,productName:item.productName})
       }
    } ></i>    </td>
       
 
        </tr>
        </>
        )
    })}
    </tbody>
    </table>
 <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{uploadmedia?.productName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <center>  
            <form onSubmit={handlesubmit} >
                <div className="row">
<div className="col-sm-6">
<div className="form-group">
<label for="exampleFormControlInput2">Enter Media Name</label>
<input type="text" className="form-control" name="productName"value={uploadmedia?.productName} onChange={handleformfield("productName")}  id="exampleFormControlInput2" placeholder="Media Name"/>
</div>
</div>

<div className="col-sm-6">
<div className="form-group">
<label for="exampleFormControlInput3"></label>
<select className="form-select" aria-label="Default select example" value={modalitem?.productCategory?.categoryName} onChange={handleformfield("categoryId")}>
<option disabled="true">{modalitem?.productCategory?.categoryName}</option>
</select>
</div>
</div>

<div className="col-sm-6">
<div className="form-group">
<label for="exampleFormControlInput4"></label>
<select className="form-select" aria-label="Default select example" value={subCategoryId} onChange={handleformfield("subCategoryId")}>
    

{subCatgory.map((i,key)=>{
  return(<>
  
  <option key={i._id} value={i._id}>{i.subCategoryName}</option>
  </>)
})}
</select>
</div>
</div>
<div className="col-sm-6">
<div className="form-group">
<label for="exampleFormControlInput2">Description</label>
<textarea class="form-control" id="exampleFormControlTextarea1" rows="3"  value={uploadmedia?.description} name="description" onChange={handleformfield("description")}></textarea>
</div> 
</div>
<br/>
<br/>

<div className="col-sm-12">
    <LazyLoadImage src={modalitem?.thumbNailPathCDN} alt="No thumbnail image"  height='100'width='100' style={{padding :'5px'}}></LazyLoadImage>
  
<div className="form-group">
    <label for="exampleFormControlInput1">Select new ThumbNail</label>
    <input type="file" className="form-control" name="myfile1" accept="image/*" onChange={(e)=>setuploadThumbNailfile(e.target.files[0])}  id="exampleFormControlInput1" placeholder="Select file"/>
</div> 
</div>

<div className="col-sm-12">

<div className="form-group">
<audio controls>
  <source src={modalitem?.productPathCDN} type="audio/ogg" />
  <source src={modalitem?.productPathCDN} type="audio/mpeg" />
  Your browser does not support the audio tag.
</audio>
<br />
    <label for="exampleFormControlInput1">Select new Media</label>
    <input type="file" className="form-control" name="myfile" onChange={(e)=>setuploadfile(e.target.files[0])}  id="exampleFormControlInput1" placeholder="Select file"/>
</div> 
</div>


<br />
<center>
<button className="btn btn-success" disabled={loading} type="submit" >SUBMIT</button> 


</center>
</div>
</form>   
                  
        
   </center>


        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>


    </>)
}

export default EditSpeech;
