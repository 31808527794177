import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions, Paper } from "@mui/material";
import AudioImage from "../Audio.png";
import ReactPlayer from "react-player/lazy";
import ReactAudioPlayer from "react-audio-player";
import { Link, NavLink } from "react-router-dom";
import BhajanImage from "../Images/Bhajan.png";
import PhotoGalleryImage from "../Images/PhotoGallery.png";
import EbooksImage from "../Images/EBooks.png";
import PravachanImage from "../Images/Pravachan.png";
import VideoImage from "../Images/Videos.png";
import Img1 from "../Images/PhotoGallery/Img1.png";
import Img2 from "../Images/PhotoGallery/Img2.png";
import Img3 from "../Images/PhotoGallery/Img3.png";
import Img4 from "../Images/PhotoGallery/Img4.png";
import Img5 from "../Images/PhotoGallery/Img5.png";
import Img6 from "../Images/PhotoGallery/Img6.png";
import Img7 from "../Images/PhotoGallery/Img7.png";
import Img8 from "../Images/PhotoGallery/Img8.png";

import Img9 from "../Images/Pravachan/Img1.png";
import Img10 from "../Images/Pravachan/Img2.png";
import Img11 from "../Images/Pravachan/Img3.png";
import Img12 from "../Images/Pravachan/Img4.png";
import Img13 from "../Images/Pravachan/Img5.png";
import Img14 from "../Images/Pravachan/Img6.png";
import Img15 from "../Images/Pravachan/Img7.png";
import Img16 from "../Images/Pravachan/Img8.png";
import Img17 from "../Images/Pravachan/Img9.png";
import Img18 from "../Images/Pravachan/Img10.png";
import Img19 from "../Images/Pravachan/Img11.png";

import Img20 from "../Images/Bhajan/Img1.png";
import Img21 from "../Images/Bhajan/Img2.png";
import Img22 from "../Images/Bhajan/Img3.png";
import Img23 from "../Images/Bhajan/Img4.png";
import Img24 from "../Images/Bhajan/Img5.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./cardItem.css";

const CardData = ({ mediaName, src, src1, description, clicked }) => {
	if (mediaName == "Image") {
		return (
			<Card
				style={{ width: "100%", height: "auto", margin: "18px auto" }}>
				<CardActionArea>
					<LazyLoadImage
						src={src}
						alt={src1}
						className="img img-responsive"
						style={{
							padding: "15px",
							width: "100%",
							height: "auto",
						}}
					/>
					<CardContent>
						<Typography
							variant="body2"
							color="text.secondary"
							style={{ textAlign: "justify" }}>
							{description}
						</Typography>
					</CardContent>
				</CardActionArea>
				<CardActions>
					<Link to="/images">
						{" "}
						<Button
							variant="outlined"
							classsName="mt-auto"
							style={{
								borderRadius: "24px",
								color: "#E34607",
								borderColor: "#E34607",
								MarginBottom: "18px",
							}}>
							Photo Gallery फोटो गैलरी{" "}
							<i
								className="fa fa-angle-right"
								style={{ padding: "6px" }}></i>
						</Button>
					</Link>
				</CardActions>
			</Card>
		);
	}

	if (mediaName == "Vedio") {
		return (
			<Card
				style={{ width: "100%", height: "auto", margin: "18px auto" }}>
				<CardActionArea>
					<ReactPlayer
						style={{
							padding: "15px",
							width: "100%",
							height: "auto",
							margin: "0 auto",
						}}
						height="auto"
						width="90%"
						url={src}
						playing={false}
						controls={true}
						config={{
							file: {
								attributes: {
									controlsList: "nodownload", //<- this is the important bit
								},
							},
						}}
					/>

					<CardContent>
						<Typography
							variant="body2"
							color="text.secondary"
							style={{ textAlign: "justify" }}>
							{description}
						</Typography>
					</CardContent>
				</CardActionArea>
				<CardActions>
					<Link to="/vedios">
						{" "}
						<Button
							variant="outlined"
							style={{
								borderRadius: "24px",
								color: "#E34607",
								borderColor: "#E34607",
								marginBottom: "18px",
							}}>
							Videos विडियोज{" "}
							<i
								className="fa fa-angle-right"
								style={{ padding: "6px" }}></i>
						</Button>
					</Link>
				</CardActions>
			</Card>
		);
	}
	if (mediaName == "Audio") {
		return (
			<Card
				style={{ width: "100%", height: "auto", margin: "18px auto" }}>
				<CardActionArea>
					<LazyLoadImage
						src={AudioImage}
						className="img img-responsive"
						style={{
							padding: "15px",
							width: "100%",
							height: "auto",
						}}
					/>

					<ReactAudioPlayer
						src={src}
						controls
						controlsList="nodownload"
					/>

					<CardContent>
						<Typography
							variant="body2"
							color="text.secondary"
							style={{ textAlign: "justify" }}>
							{description}
						</Typography>
					</CardContent>
				</CardActionArea>
				<CardActions>
					<Link to="/audios">
						{" "}
						<Button
							variant="outlined"
							style={{
								borderRadius: "24px",
								color: "#E34607",
								borderColor: "#E34607",
								marginBottom: "18px",
							}}>
							MORE AUDIOS
						</Button>{" "}
					</Link>
				</CardActions>
			</Card>
		);
	}

	if (mediaName == "Songs") {
		return (
			<Card
				style={{ width: "100%", height: "auto", margin: "18px auto" }}>
				<CardActionArea>
					<LazyLoadImage
						src={BhajanImage}
						className="img img-responsive"
						style={{
							padding: "15px",
							width: "100%",
							height: "auto",
						}}
					/>
					<CardContent>
						<Typography variant="body2" color="text.secondary">
							<ReactAudioPlayer
								style={{ width: "100%" }}
								src={src}
								controls
								controlsList="nodownload"
							/>
							{description}
						</Typography>
					</CardContent>
				</CardActionArea>
				<CardActions>
					<Link to="/songs">
						{" "}
						<Button
							variant="outlined"
							style={{
								borderRadius: "24px",
								color: "#E34607",
								borderColor: "#E34607",
								marginBottom: "18px",
							}}>
							{" "}
							More Songs भजन{" "}
							<i
								className="fa fa-angle-right"
								style={{ padding: "6px" }}></i>
						</Button>
					</Link>
				</CardActions>
			</Card>
		);
	}

	if (mediaName == "Speech") {
		return (
			<Card
				style={{ width: "100%", height: "auto", margin: "18px auto" }}>
				<CardActionArea>
					<LazyLoadImage
						src={PravachanImage}
						className="img img-responsive"
						style={{
							padding: "15px",
							width: "100%",
							height: "auto",
						}}
					/>
					<CardContent>
						<Typography variant="body2" color="text.secondary">
							<ReactAudioPlayer
								style={{ width: "100%" }}
								src={src}
								controls
								controlsList="nodownload"
							/>
							{description}
						</Typography>
					</CardContent>
				</CardActionArea>
				<CardActions>
					<Link to="/speech">
						{" "}
						<Button
							variant="outlined"
							style={{
								borderRadius: "24px",
								color: "#E34607",
								borderColor: "#E34607",
								marginBottom: "18px",
							}}>
							Discourses प्रवचन{" "}
							<i
								className="fa fa-angle-right"
								style={{ padding: "6px" }}></i>
						</Button>
					</Link>
				</CardActions>
			</Card>
		);
	}

	if (mediaName == "content") {
		return (
			<Card
				style={{ width: "100%", height: "auto", margin: "18px auto" }}>
				<CardActionArea>
					<center>
						<ReactPlayer
							style={{
								padding: "15px",
								width: "100%",
								height: "auto",
							}}
							height="auto"
							width="100%"
							url={`https://hkm.sgp1.digitaloceanspaces.com/FrontendFiles/GLIMPSES_Ek%20Jhalak_02.mp4`}
							playing={false}
							controls={true}
							config={{
								file: {
									attributes: {
										controlsList: "nodownload", //<- this is the important bit
									},
								},
							}}
						/>
					</center>
					<CardContent>
						<Typography
							variant="body2"
							color="text.secondary"
							style={{
								textAlign: "left",
								fontFamily: "Poppins",
							}}>
							{description}
						</Typography>
					</CardContent>
				</CardActionArea>
				<CardActions>
					<Button
						variant="outlined"
						onClick={clicked}
						style={{
							borderRadius: "24px",
							color: "#E34607",
							borderColor: "#E34607",
							display: "none",
						}}
						id="vanish">
						Learn more
					</Button>
				</CardActions>
			</Card>
		);
	}

	if (mediaName == "content1") {
		return (
			<Card
				style={{
					width: "100%",
					height: "auto",
					margin: "18px auto",
					backgroundColor: "#f5f5f5",
				}}>
				<CardActionArea>
					<CardContent>
						<h3 style={{ color: "#E34607", lineHeight: "34px" }}>
							Photo Gallery
						</h3>
						<Typography
							variant="body2"
							color="text.secondary"
							style={{ textAlign: "left" }}>
							<center>
								{" "}
								<LazyLoadImage
									src={PhotoGalleryImage}
									style={{
										height: "min(100%, 250px)",
										width: "min(100%, 250px)",
										objectFit: "contain",
									}}
									className="img img-responsive"
									alt="Image here"></LazyLoadImage>
							</center>
							<br />
							<p>{description}</p>
							<br />
							<div className=" row-horizon d-flex flex-row flex-nowrap overflow-auto">
								<Link to="/category/Images/Beggar%20princess">
									{" "}
									<img
										src={Img1}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>

								<Link to="/category/Images/Candids">
									{" "}
									<img
										src={Img2}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>

								<Link to="/category/Images/Dadaji%20&%20Ma">
									{" "}
									<img
										src={Img3}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>

								<Link to="/category/Images/Dadaji">
									{" "}
									<img
										src={Img4}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>

								<Link to="/category/Images/Hari%20krishna%20Mandir">
									{" "}
									<img
										src={Img5}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>

								<Link to="/category/Images/Ma%20in%20Bhaav">
									{" "}
									<img
										src={Img6}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>

								<Link to="/category/Images/Ma%20Portraits">
									{" "}
									<img
										src={Img7}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>

								<Link to="/category/Images/Ma%20with%20Devotees">
									<img
										src={Img8}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>
							</div>
						</Typography>
					</CardContent>
				</CardActionArea>
				<CardActions>
					<Link to="/images">
						{" "}
						<Button
							variant="outlined"
							style={{
								borderRadius: "24px",
								color: "#E34607",
								borderColor: "#E34607",
							}}>
							Load more
						</Button>
					</Link>
				</CardActions>
			</Card>
		);
	}
	if (mediaName == "content3") {
		return (
			<Card
				style={{
					width: "100%",
					height: "auto",
					margin: "18px auto",
					backgroundColor: "#f5f5f5",
				}}>
				<CardActionArea>
					<CardContent>
						<h3 style={{ color: "#E34607", lineHeight: "34px" }}>
							Songs
						</h3>
						<Typography
							variant="body2"
							color="text.secondary"
							style={{ textAlign: "left" }}>
							<center>
								{" "}
								<img
									src={BhajanImage}
									style={{
										height: "min(100%, 250px)",
										width: "min(100%, 250px)",
										objectFit: "contain",
									}}
									className="img img-responsive"
									alt="Image here"></img>
							</center>
							<br />
							<p>{description}</p>
							<br />
							<div className=" row-horizon d-flex flex-row flex-nowrap overflow-auto horiz">
								<Link to="/category/Songs/Bengali%20Songs">
									{" "}
									<img
										src={Img21}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>
								<Link to="/category/Songs/Bholenath">
									<img
										src={Img22}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>
								<Link to="/category/Songs/Devi%20Ma">
									<img
										src={Img20}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>
								</Link>

								<Link to="/category/Songs/Krishna">
									<img
										src={Img23}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>
								<Link to="/category/Songs/Others">
									{" "}
									<img
										src={Img24}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>
								</Link>
							</div>
						</Typography>
					</CardContent>
				</CardActionArea>
				<CardActions>
					<Link to="/songs">
						{" "}
						<Button
							variant="outlined"
							style={{
								borderRadius: "24px",
								color: "#E34607",
								borderColor: "#E34607",
							}}>
							Load more
						</Button>
					</Link>
				</CardActions>
			</Card>
		);
	}
	if (mediaName == "content4") {
		return (
			<Card
				style={{
					width: "100%",
					height: "auto",
					margin: "18px auto",
					backgroundColor: "#f5f5f5",
				}}>
				<CardActionArea>
					<CardContent>
						<h3 style={{ color: "#E34607", lineHeight: "34px" }}>
							E-Books
						</h3>
						<Typography
							variant="body2"
							color="text.secondary"
							style={{ textAlign: "left" }}>
							<center>
								{" "}
								<img
									src={EbooksImage}
									style={{ height: "300px", width: "300px" }}
									className="img img-responsive"
									alt="Image here"></img>
							</center>
							<br />
							<p>{description}</p>
							<br />
							<div className=" row-horizon d-flex flex-row flex-nowrap overflow-auto"></div>
						</Typography>
					</CardContent>
				</CardActionArea>
				<CardActions>
					<Link to="/document">
						{" "}
						<Button
							variant="outlined"
							style={{
								borderRadius: "24px",
								color: "#E34607",
								borderColor: "#E34607",
							}}>
							Load more
						</Button>
					</Link>
				</CardActions>
			</Card>
		);
	}

	if (mediaName == "content2") {
		return (
			<Card
				style={{
					width: "100%",
					height: "auto",
					margin: "18px auto",
					backgroundColor: "#f5f5f5",
				}}>
				<CardActionArea>
					<CardContent>
						<h3 style={{ color: "#E34607", lineHeight: "34px" }}>
							Discourses
						</h3>
						<Typography
							variant="body2"
							color="text.secondary"
							style={{ textAlign: "left" }}>
							<center>
								{" "}
								<img
									src={PravachanImage}
									style={{
										height: "min(100%, 250px)",
										width: "min(100%, 250px)",
										objectFit: "contain",
									}}
									className="img img-responsive"
									alt="Image here"></img>
							</center>
							<br />
							<p>{description}</p>
							<br />
							<div className=" row-horizon d-flex flex-row flex-nowrap overflow-auto">
								<Link to="/category/Speech/All%20Saints">
									{" "}
									<img
										src={Img9}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>
								<Link to="/category/Speech/Dadaji">
									<img
										src={Img10}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>
								<Link to="/category/Speech/Divine">
									<img
										src={Img11}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>
								<Link to="/category/Speech/Guru%20Granth%20Sahib">
									{" "}
									<img
										src={Img12}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>
								<Link to="/category/Speech/Guruvaad">
									{" "}
									<img
										src={Img13}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>
								<Link to="/category/Speech/Kathamrita">
									{" "}
									<img
										src={Img14}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>
								<Link to="/category/Speech/Ma%20Indira%20Devi">
									{" "}
									<img
										src={Img15}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>
								<Link to="/category/Speech/Mirabai">
									{" "}
									<img
										src={Img16}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>
								<Link to="/category/Speech/Musings">
									{" "}
									<img
										src={Img17}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>
								<Link to="/category/Speech/Parables">
									{" "}
									<img
										src={Img18}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>
								<Link to="/category/Speech/Ramayana">
									{" "}
									<img
										src={Img19}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>
							</div>
						</Typography>
					</CardContent>
				</CardActionArea>
				<CardActions>
					<Link to="/speech">
						{" "}
						<Button
							variant="outlined"
							style={{
								borderRadius: "24px",
								color: "#E34607",
								borderColor: "#E34607",
							}}>
							Load more
						</Button>
					</Link>
				</CardActions>
			</Card>
		);
	}

	if (mediaName == "content5") {
		return (
			<Card
				style={{
					width: "100%",
					height: "auto",
					margin: "18px auto",
					backgroundColor: "#f5f5f5",
				}}>
				<CardActionArea>
					<CardContent>
						<h3 style={{ color: "#E34607", lineHeight: "34px" }}>
							Videos
						</h3>
						<Typography
							variant="body2"
							color="text.secondary"
							style={{ textAlign: "left" }}>
							<center>
								{" "}
								<img
									src={VideoImage}
									style={{
										height: "min(100%, 250px)",
										width: "min(100%, 250px)",
										objectFit: "contain",
									}}
									className="img img-responsive"
									alt="Image here"></img>
							</center>
							<br />
							<p>{description}</p>
							<br />
							<div className=" row-horizon d-flex flex-row flex-nowrap overflow-auto horiz">
								<Link to="/category/Videos/All%20Saints">
									{" "}
									<img
										src={Img9}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>
								<Link to="/category/Videos/Dadaji">
									{" "}
									<img
										src={Img10}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>
								<Link to="/category/Videos/Divine">
									{" "}
									<img
										src={Img11}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>
								<Link to="/category/Videos/Guru%20Granth%20Sahib">
									{" "}
									<img
										src={Img12}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>
								<Link to="/category/Videos/Guruvaad">
									{" "}
									<img
										src={Img13}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>
								<Link to="/category/Videos/Devi%20Ma">
									{" "}
									<img
										src={Img20}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>
								<Link to="/category/Videos/Krishna">
									{" "}
									<img
										src={Img23}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>
								<Link to="/category/Videos/Musings">
									{" "}
									<img
										src={Img17}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>
								<Link to="/category/Videos/Parables">
									{" "}
									<img
										src={Img18}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>{" "}
								</Link>
								<Link to="/category/Videos/Ramayana">
									{" "}
									<img
										src={Img19}
										style={{
											height: "150px",
											width: "150px",
											padding: "3px",
										}}
									/>
								</Link>
							</div>
						</Typography>
					</CardContent>
				</CardActionArea>
				<CardActions>
					<Link to="/vedios">
						{" "}
						<Button
							variant="outlined"
							style={{
								borderRadius: "24px",
								color: "#E34607",
								borderColor: "#E34607",
							}}>
							Load more
						</Button>
					</Link>
				</CardActions>
			</Card>
		);
	}
};

export default CardData;
