import { API } from "../../backend";

export const gethome=async()=>{
    return await fetch(`${API}/user/media/getIndexPageData`,{
        method:"GET",
        headers:{
            Accept:"application/json",
            "Content-Type":"application/json",
           
        },
        
    }).then((response)=>{
        console.log(response)
     return response.json()
    }).catch((e)=>{
      console.log(e)
    })
}

export const mainpageData=async()=>{
    return await fetch(`${API}/user/allcats`,{
        method:"GET",
        headers:{
            Accept:"application/json",
            "Content-Type":"application/json",
           
        },
        
    }).then((response)=>{
        console.log(response)
     return response.json()
    }).catch((e)=>{
      console.log(e)
    })
}

export const verifyLink=async(userId,uid)=>{
    return await fetch(`${API}user/verifylink/${userId}?uid=${uid}`,{
        method:"GET",
        headers:{
            Accept:"application/json",
            "Content-Type":"application/json",
           
        },
        
    }).then((response)=>{
        console.log(response)
     return response.json()
    }).catch((e)=>{
      console.log(e)
    })
}

export const verifyMobileNo=async(userId)=>{
    return await fetch(`${API}user/sendmail/${userId}`,{
        method:"GET",
        headers:{
            Accept:"application/json",
            "Content-Type":"application/json",
           
        },
        
    }).then((response)=>{
        console.log(response)
     return response.json()
    }).catch((e)=>{
      console.log(e)
    })
}

export const updatePassword=async(userId,data)=>{
    return await fetch(`${API}user/updatepassword/${userId}`,{
        method:"POST",
        headers:{
            Accept:"application/json",
            "Content-Type":"application/json",
           
        },
        body:JSON.stringify(data)
        
    }).then((response)=>{
        console.log(response)
     return response.json()
    }).catch((e)=>{
      console.log(e)
    })
}