import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { updatePassword, verifyLink } from "./getapi";
import Header from "./Header";


const LinkExpiry=(req,res)=>{
    const [isExpired,setExpiry]=useState(false)
    const [formValue,setFormValue] =useState({
        confirmPassword:"",
        confirmPassword1:"",
        otp:""
    })
    const{confirmPassword,confirmPassword1,otp}=formValue;
    const handleChange=name=>e=>{
        console.log(name,e)
        setFormValue({...formValue, [name]:e.target.value})
    }
    let Navigate=useNavigate()

    const handleSubmit=async(e)=>{
        console.log(formValue)
        e.preventDefault();

        if( confirmPassword &&  confirmPassword1 && confirmPassword===confirmPassword1 && confirmPassword.length>=6 && otp.length==4)
        {
        await updatePassword(userId,{confirmPassword,otp}).then(a=>{
            console.log(a)
            if(a?.error){
                alert(a.error);
                return false
            }
            if(a?.errormessage)
            {
            alert(a?.errormessage);
            return false
            }
            if(a?.message){
            alert(a?.message);
            Navigate("/signin")
            }
         }).catch(e=>{
            alert("Failed to fetch data.Please try again later")
         })
        }
        else{
            alert("Enter valid credentials")
            return false;
        }

        }


    
    
    const { userId,uid} = useParams();
    useEffect(()=>{
    verifyLink(userId,uid).then(data=>{
        if(data.result)
        {
        setExpiry(data.result)
        }
        if(data.errorMessage){
            alert(data.errorMessage);
            return false
        }
        if(data.error){
            setExpiry(true)
        }
    }).catch(e=>{
        alert("Failed to fetch data")
        return false
    })
    })
    return(<>
    <Header />
    <br />
    {isExpired ? (<>
    <h2>Link is expired</h2>
    </> ):( <>
    <center><h2>Confirm Password</h2>

    <form className="col-sm-4">

    <div className="form-group">
      <input type="number" name="otp" onChange={handleChange("otp")} value={otp} placeholder="Enter OTP" className="form-control" style={{borderRadius:"15px 15px 15px 15px"}}>
      </input>
  </div>
  <br/>
  <div className="form-group">
      <input type="password" name="confirmPassword" onChange={handleChange("confirmPassword")} value={confirmPassword} placeholder="Enter password" className="form-control" style={{borderRadius:"15px 15px 15px 15px"}}>

      </input>
  </div>
  <br/>

  <div className="form-group">
      <input type="password" name="confirmPassword1" onChange={handleChange("confirmPassword1")} value={confirmPassword1} placeholder="Re-type password" className="form-control" style={{borderRadius:"15px 15px 15px 15px"}}>
      </input>
  </div>
<br />

  </form>
  <button type="button" onClick={handleSubmit} className="btn btn-info" style={{margin:"15px",backgroundColor:"#E34607"}}>Save Password</button>
    </center>
    
    </>)}
    
    </>)
}

export default LinkExpiry