import { useEffect, useState } from "react";
import { getImageData } from ".";
import LoggedInNavbar from "./loggedInNavbar";

import { Link, useParams, useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../Auth/helper";

const DisplayData = (props) => {
  const [prdtdt, setprdt] = useState("");
  const [product, setAllProducts] = useState("");
  const { userId } = useParams();
  const navigate = useNavigate();

  useEffect(async () => {
    let params = new URLSearchParams(document.location.search);
    let urrl = params.get("id");

    const { user, token } = isAuthenticated();
    await getImageData(user._id, token, userId)
      .then((x) => {
        console.log(x.description);
        setAllProducts(x.description);
      })
      .catch();

    setprdt(urrl);
  }, []);

  return (
    <>
      <LoggedInNavbar />
      <br />
      <div className="row">
        <div className="col-sm-12">
          <center>
            <br />
            <img src={prdtdt} style={{ width: "50vw", height: "50vw" }}></img>
            <br />
            {product}
            <br />
            <br />
            <button onClick={() => navigate(-1)} className="btn btn-success">
              Go back
            </button>
          </center>
        </div>
      </div>
    </>
  );
};

export default DisplayData;
