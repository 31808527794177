import { useState } from "react";
import Logo from "../../User/helper/logo.png";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBCollapse,
  MDBNavbarLink,
  MDBNavbarItem,
} from "mdb-react-ui-kit";

import { Link, useNavigate } from "react-router-dom";

const LoggedInNavbar = () => {
  const [showBasic, setShowBasic] = useState(false);
  let navi = useNavigate();

  return (
    <div>
      <MDBNavbar light bgColor="light" style={{ overflow: "hidden" }}>
        <MDBContainer fluid>
          <Link to="/">
            <MDBNavbarBrand>
              <img src={Logo} alt="logo" height="30px"></img>
            </MDBNavbarBrand>
          </Link>
          <MDBNavbarToggler
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowBasic(!showBasic)}
          >
            <MDBIcon icon="bars" fas style={{ color: "#E34607" }} />
          </MDBNavbarToggler>
          <MDBCollapse navbar show={showBasic}>
            <MDBNavbarNav className="mr-auto mb-2 mb-lg-0">
              <Link to="/images">
                {" "}
                <MDBNavbarLink>
                  {" "}
                  <MDBNavbarItem> Photo Gallery फोटो गैलरी </MDBNavbarItem>
                </MDBNavbarLink>
              </Link>
              <Link to="/speech">
                {" "}
                <MDBNavbarLink>
                  {" "}
                  <MDBNavbarItem> Discourses प्रवचन </MDBNavbarItem>{" "}
                </MDBNavbarLink>
              </Link>
              <Link to="/songs">
                <MDBNavbarLink>
                  <MDBNavbarItem>Songs भजन </MDBNavbarItem>
                </MDBNavbarLink>
              </Link>
              <Link to="/vedios">
                {" "}
                <MDBNavbarLink>
                  {" "}
                  <MDBNavbarItem>Videos विडियोज </MDBNavbarItem>
                </MDBNavbarLink>
              </Link>
              <Link to="/">
                {" "}
                <MDBNavbarLink>
                  {" "}
                  <MDBNavbarItem>Hare Krishna Mandir, Pune </MDBNavbarItem>
                </MDBNavbarLink>
              </Link>
              {/* <Link to="/">
                {" "}
                <MDBNavbarLink>
                  {" "}
                  <MDBNavbarItem> Hare Krishna Mandir Trust </MDBNavbarItem>
                </MDBNavbarLink>
              </Link> */}
              <Link to="/">
                {" "}
                <MDBNavbarLink>
                  {" "}
                  <MDBNavbarItem>Contact us </MDBNavbarItem>
                </MDBNavbarLink>
              </Link>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </div>
  );
};

export default LoggedInNavbar;
