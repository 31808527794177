import { useState } from "react";
import {
  authenticate,
  isAuthenticated,
  triggerSignin,
} from "../../Auth/helper";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Header from "./Header";
import { verifyMobileNo } from "./getapi";

const Signin = () => {
  const [show, setShow] = useState(false);
  const [isdisabled, setDisabled] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const togle = () => setDisabled(true);
  const togle1 = () => setDisabled(false);
  const [formValue, setFormValue] = useState({
    MobileNo: "",
    password: "",
    errorMessage: false,
  });

  let Navigate = useNavigate();
  const { MobileNo, password, errorMessage } = formValue;

  const handleChange = (name) => (e) => {
    setFormValue({ ...formValue, [name]: e.target.value });
  };

  const sendmail = async () => {
    if (MobileNo && MobileNo.length >= 8 && MobileNo.length < 15) {
      await verifyMobileNo(MobileNo)
        .then((data) => {
          if (data?.error) {
            alert(data.error);
            setDisabled(!isdisabled);
            return false;
          }
          if (data?.message) {
            alert(data.message);
            handleClose();
          }

          setDisabled(!isdisabled);
        })
        .catch((e) => {
          alert("Failed to feth data.Please try again later");
          return false;
        });
    } else {
      alert("Enter a valid mobile number");
      return false;
    }
  };

  const handleSubmit = async (e) => {
    console.log(formValue);
    e.preventDefault();
    setFormValue({ ...formValue, errorMessage: false });
    if (MobileNo == "" || password == "") {
      setFormValue({ ...formValue, errorMessage: "Enter valid credentials" });
      return false;
    }
    await triggerSignin({ MobileNo, password }).then((r) => {
      if (r?.error) {
        setFormValue({ ...formValue, errorMessage: r.error });
      } else {
        authenticate(r);
        if (isAuthenticated()?.user?.Role == 1) {
          Navigate("/");
        } else {
          Navigate("/adminDashboard/");
        }
      }
    });
  };
  return (
    <>
      <Header />
      <br />
      <br />

      <div className="container" style={{ backgroundColor: "white" }}>
        {" "}
        <p
          style={{
            textAlign: "center",
            fontSize: "24px",
            marginBottom: "-6px",
          }}
        >
          Hari Krishna Mandir
        </p>
        <div className="row">
          <div className="col-sm-3"></div>
          <center
            style={{
              borderRadius: "15px 15px 15px 15px",
              marginTop: "40px",
            }}
          >
            <form className="col-sm-3">
              <div className="form-group">
                <input
                  type="number"
                  name="mobileNo"
                  onChange={handleChange("MobileNo")}
                  placeholder="Mobile"
                  className="form-control"
                  style={{
                    borderRadius: "15px 15px 15px 15px",
                    width: "90%",
                    padding: "6px 12px",
                  }}
                ></input>
              </div>
              <br />
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  onChange={handleChange("password")}
                  placeholder="Password"
                  className="form-control"
                  style={{
                    borderRadius: "15px 15px 15px 15px",
                    width: "90%",
                    padding: "6px 12px",
                  }}
                ></input>
              </div>
            </form>
          </center>
          <br />
          <br />
          <center>
            {" "}
            <div
              className="ButtonContainer col-sm-3"
              style={{
                margin: "15px",
                backgroundColor: "#F5F5F5",
                display: "flex",
                flexDirection: "column",
                padding: "20px 0px 10px",
              }}
            >
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-info"
                style={{
                  margin: "15px",
                  backgroundColor: "#E34607",
                  letterSpacing: "1px",
                  fontSize: "14px",
                }}
              >
                Sign in
              </button>
              <button
                type="button"
                onClick={handleShow}
                className="btn btn-info"
                style={{
                  margin: "15px",
                  backgroundColor: "white",
                  border: "1px solid #E34607",
                  color: "#E34607",
                  boxShadow: "0px 0px lightblue",
                  letterSpacing: "1px",
                  fontSize: "14px",
                }}
              >
                Forgot Password ?
              </button>

              <div
                className="nuwUser"
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h4 style={{ padding: "8px 18px 0px" }}> New User ?</h4>

                <Link to="/signup">
                  {" "}
                  <button
                    type="button"
                    className="btn btn-info"
                    style={{
                      margin: "15px",
                      backgroundColor: "white",
                      border: "1px solid #E34607",
                      color: "#E34607",
                      boxShadow: "0px 0px lightblue",
                      letterSpacing: "1px",
                      fontSize: "14px",
                    }}
                  >
                    Sign up
                  </button>
                </Link>
              </div>
            </div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Forgot Password</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <center>
                  {" "}
                  <form className="col-sm-8">
                    <div className="form-group">
                      <input
                        type="email"
                        name="mobileNo"
                        onChange={handleChange("MobileNo")}
                        placeholder="MobileNo"
                        className="form-control"
                        style={{ borderRadius: "15px 15px 15px 15px" }}
                      ></input>
                    </div>
                    <br />
                  </form>
                </center>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  style={{
                    margin: "15px",
                    backgroundColor: "#E34607",
                    letterSpacing: "1px",
                  }}
                  variant="primary"
                  disabled={isdisabled}
                  onClick={() => {
                    sendmail();
                  }}
                >
                  Send Email link
                </Button>
              </Modal.Footer>
            </Modal>
          </center>

          <br />
          {errorMessage && (
            <div
              className="alert alert-warning alert-dismissible fade show"
              role="alert"
            >
              <strong>{errorMessage}</strong>
            </div>
          )}
        </div>
        <div className="col-4"></div>
      </div>
    </>
  );
};
export default Signin;
