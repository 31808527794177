
import { API } from "../../backend"

export const getAllCategory=(id,token)=>{
  
  //console.log(id,token)
    return fetch(`${API}user/getcategory/${id}`,{
        method:"GET",
        headers:{
           
            Accept:"application/json",
            "Content-Type":"application/json",
            Authorization:`Bearer ${token}`
        },
        
    }).then(allcat=>{
        return allcat.json()
    }).catch((e)=>{
       return e
    })
}


export const getAllProducts=(id,token,catId)=>{
  
   
      return fetch(`${API}user/${id}/${catId}`,{
          method:"GET",
          headers:{
             
              Accept:"application/json",
              "Content-Type":"application/json",
              Authorization:`Bearer ${token}`
          },
          
      }).then(allcat=>{
          //console.log(allcat)
          return allcat.json()
      }).catch((e)=>{
         return e
      })
  }

  export const getAllVedios=(id,token)=>{
  
   
    return fetch(`${API}user/vedios/${id}`,{
        method:"GET",
        headers:{
           
            Accept:"application/json",
            "Content-Type":"application/json",
            Authorization:`Bearer ${token}`
        },
        
    }).then(allcat=>{
        //console.log(allcat)
        return allcat.json()
    }).catch((e)=>{
       return e
    })
}

export const getAllVediosWithout=()=>{
  
   
    return fetch(`${API}user/allvedios/`,{
        method:"GET",
        headers:{
           
            Accept:"application/json",
            "Content-Type":"application/json",
        },
        
    }).then(allcat=>{
        //console.log(allcat)
        return allcat.json()
    }).catch((e)=>{
       return e
    })
}

export const getAllAudiosWithout=()=>{
  
   
    return fetch(`${API}user/allaudios/`,{
        method:"GET",
        headers:{
           
            Accept:"application/json",
            "Content-Type":"application/json",
        },
        
    }).then(allcat=>{
        //console.log(allcat)
        return allcat.json()
    }).catch((e)=>{
       return e
    })
}

export const getAllSpeechWithout=(id,token)=>{
  
  
    return fetch(`${API}user/allspeech/`,{
        method:"GET",
        headers:{
           
            Accept:"application/json",
            "Content-Type":"application/json",
        },
        
    }).then(allcat=>{
        //console.log(allcat)
        return allcat.json()
    }).catch((e)=>{
       return e
})
}
export const getAllSongsWithout=(id,token)=>{
  
  
    return fetch(`${API}user/allsongs/`,{
        method:"GET",
        headers:{
           
            Accept:"application/json",
            "Content-Type":"application/json",
        },
        
    }).then(allcat=>{
        //console.log(allcat)
        return allcat.json()
    }).catch((e)=>{
       return e
})
}

export const getAllImagesWithout=(id,token)=>{
  
  
    return fetch(`${API}user/allimages/`,{
        method:"GET",
        headers:{
           
            Accept:"application/json",
            "Content-Type":"application/json",
        },
        
    }).then(allcat=>{
        //console.log(allcat)
        return allcat.json()
    }).catch((e)=>{
       return e
})
}

export const getAllImages=(id,token)=>{
  
   
    return fetch(`${API}user/images/${id}`,{
        method:"GET",
        headers:{
           
            Accept:"application/json",
            "Content-Type":"application/json",
            Authorization:`Bearer ${token}`
        },
        
    }).then(allcat=>{
        //console.log(allcat)
        return allcat.json()
    }).catch((e)=>{
       return e
    })
}

export const getAllSongs=(id,token)=>{
  
   
    return fetch(`${API}user/songs/${id}`,{
        method:"GET",
        headers:{
           
            Accept:"application/json",
            "Content-Type":"application/json",
            Authorization:`Bearer ${token}`
        },
        
    }).then(allcat=>{
        //console.log(allcat)
        return allcat.json()
    }).catch((e)=>{
       return e
    })
}

export const getAllSpeech=(id,token)=>{
  
   
    return fetch(`${API}user/speech/${id}`,{
        method:"GET",
        headers:{
           
            Accept:"application/json",
            "Content-Type":"application/json",
            Authorization:`Bearer ${token}`
        },
        
    }).then(allcat=>{
        //console.log(allcat)
        return allcat.json()
    }).catch((e)=>{
       return e
    })
}

export const getImageData=(id,token,musicId)=>{
    return fetch(`${API}user/images/${id}/${musicId}`,{
        method:"GET",
        headers:{
           
            Accept:"application/json",
            "Content-Type":"application/json",
            Authorization:`Bearer ${token}`
        },
        
        
    }).then(allcat=>{
        //console.log(allcat)
        return allcat.json()
    }).catch((e)=>{
       return e
    })

}



export const getAllDocuments=(id,token)=>{
  
   
    return fetch(`${API}user/documents/${id}`,{
        method:"GET",
        headers:{
           
            Accept:"application/json",
            "Content-Type":"application/json",
            Authorization:`Bearer ${token}`
        },
        
    }).then(allcat=>{
        //console.log(allcat)
        return allcat.json()
    }).catch((e)=>{
       return e
    })
}
export const getAllAudios=(id,token)=>{
  
   
    return fetch(`${API}user/audios/${id}`,{
        method:"GET",
        headers:{
           
            Accept:"application/json",
            "Content-Type":"application/json",
            Authorization:`Bearer ${token}`
        },
        
    }).then(allcat=>{
        //console.log(allcat)
        return allcat.json()
    }).catch((e)=>{
       return e
    })
}




  export const getSingleProducts=async(id,token,productId)=>{
   console.log("fff")
    return await fetch(`${API}user/getproduct/${id}/${productId}`,{
        method:"GET",
        headers:{
           
            Accept:"application/json",
           
            Authorization:`Bearer ${token}`
        },
        
    }).then(file=>{
        console.log(file.blob)
        return file.blob;
       // return file.json()
    }).catch((e)=>{
       return e
    })
      

  }






  export const allActiveMusicArray=async(id,token)=>{
      console.log(id,token)
    return  fetch(`${API}user/getactivemusicarray/${id}`,{
        method:"GET",
        headers:{
           
            Accept:"application/json",
            "Content-Type":"application/json",
            Authorization:`Bearer ${token}`
        },
        
    }).then(rs=>{
        
        return rs.json();
       // return file.json()
    }).catch((e)=>{
       return e
    })
      

  }



  export const sendbookmark=async(id,token,data)=>{
    console.log(id,token)
  return  fetch(`${API}user/sendbookmark/${id}`,{
      method:"POST",
      headers:{
         
          Accept:"application/json",
          "Content-Type":"application/json",
          Authorization:`Bearer ${token}`
      },
      body:JSON.stringify(data)
      
  }).then(rs=>{
      
      return rs.json();
     // return file.json()
  }).catch((e)=>{
     return e
  })
    

}

export const getCategoryData=async(id,token,data)=>{
    console.log(id,token)
  return  fetch(`${API}user/categorywise/${id}`,{
      method:"POST",
      headers:{
         
          Accept:"application/json",
          "Content-Type":"application/json",
          Authorization:`Bearer ${token}`
      },

      body:JSON.stringify(data)
      
  }).then(rs=>{
      
      return rs.json();
     // return file.json()
  }).catch((e)=>{
     return e
  })
    

}


export const updatePoduct=( id,token,productId,categoryId,data)=>{
    console.log(id,token)
    return  fetch(`${API}admin/updatefile/${id}/${productId}/${categoryId}`,{
        method:"POST",
        headers:{
            Authorization:`Bearer ${token}`
        },
  
        body:data
        
    }).then(rs=>{
        
        return rs.json();
       // return file.json()
    }).catch((e)=>{
       return e
    })
      

}

export const falsyToemptyString=(args)=>{

        return args || ''
    
}