import React, {  useEffect,useState } from "react";
import {  getAllAudiosWithout} from ".";
import { isAuthenticated } from "../../Auth/helper"
import LoggedInNavbar from "./loggedInNavbar";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";






const UserPlay=()=>{
    
    const navigate = useNavigate();
    let [products,setAllProducts]=useState([])


 let [mainCat,setMainCat]=useState([])
 let [catThumbNail,setCatThumbNail]=useState([])


    const checkSignedIn=(id,musicid,item)=>{
      console.log(id,musicid)
     const isSignedin= isAuthenticated() && isAuthenticated().token && isAuthenticated().user._id
      if(isSignedin)
      {
        //
       navigate(`/audioplayer/${id}/${musicid}?way=${item}`)
      }
      else{
      navigate("/signin")
      }
    }

    useEffect(()=>{
        console.log("This is useeffect")
        const fetchdata=async ()=>{
        try{    
       await getAllAudiosWithout().then(x=>{
         console.log(x)
      

         const getsubCategory=x.map(item=>item.subCategoryId)
         let uniqueObjArray = [...new Map(getsubCategory.map((item) => [item["subCategoryName"], item])).values()];
         console.log(uniqueObjArray)
        
          const mainCategory=uniqueObjArray.map(item=>item.subCategoryName)
          const thumbArr=uniqueObjArray.map(item=>item.subCategoryImageCDN)            


          
            setMainCat(mainCategory)
            setCatThumbNail(thumbArr)
            setAllProducts(x)

        }).catch()

// await allActiveMusicArray(c.user._id,c.token).then((as)=>{
//             console.log(as.Bookmark)
//             let newBookmark=new Array();
//           as.Bookmark.map((r,n)=>{
//               console.log(r)
//               if(r.CategoryName==mediatype){
//                   console.log("helo")
//                 newBookmark.push(r)
//               }
//           })
//           console.log(newBookmark)
          

//             setplayed(as.ActiveMusic)
//             setbookmark(newBookmark.reverse())
//           }).catch((e)=>{
//               alert("Failed to load resource");
//               return false;
//           })
      
    }
    
    catch{

    }
}

fetchdata()
//getActiveMusicArray()
    },[])

const showCategory=(id)=>{
  const Video="Audios"
   navigate(`/category/${Video}/${id}`)
}
    

    return(
        <div>
 <LoggedInNavbar/>
        <br/>
        <div className="container">
    
        <h2 style={{color:'#E34607',lineHeight:"34px",padding:"20px"}}>Audios</h2>
        

        <div className=" d-flex flex-row flex-nowrap overflow-auto"> 
        { 
              mainCat.map((item,index)=>{
                return (
                   
                    <div className="col-sm-3">
                        <center>                    <div className="card" onClick={()=>showCategory(item)} style={{"width": '18rem', cursor:"pointer"}}>
  <div className="card-body">
    <h5 className="card-title" style={{color:'#E34607'}}>{item}</h5>
    <LazyLoadImage src={catThumbNail[index]} alt={item}  height="200" width="200" loading="lazy"/>
     
   

  </div>
  </div>
  </center>
 
  </div>

                )
              })

            }
  </div>
     
    <br />
    <br/>

 <div className="row">
 {products.map((item,key)=>
        {
        
            return(
        
              <div className="col-sm-4" style={{"padding":"13px"}}>
              <center>
              <div className="card" style={{flexDirection: "row",cursor:"pointer",backgroundColor:'#f5f5f5',height:"100px"}}  onClick={()=>checkSignedIn(item._id,item.productCategory._id,item.productPathCDN)}>
<LazyLoadImage src={item.thumbNailPathCDN} style={{width: "30%"}}  alt="gvg" loading="lazy"/>
<div className="card-body">
<h6 className="card-title">{item.productName}</h6>
</div>
</div>
  
        </center>
        </div>

)
      
    }
)}


 </div>

    
        </div>
  
    </div>

)}

export default React.memo(UserPlay);