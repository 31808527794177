import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Grid from "@mui/material/Grid";
import { gethome } from "./getapi";
import CardData from "./IndexComponent/CardItem";
import { Link } from "react-router-dom";
import { isAuthenticated } from "../../Auth/helper";
import LoggedInNavbar from "../../Admin/helper/loggedInNavbar";
import { falsyToemptyString } from "../../Admin/helper";

const Home = () => {
	var cardStyle = {
		width: "100%",
	};

	const [receive, getdata] = useState([]);
	console.log("rcv", receive);
	let abc;
	useEffect(() => {
		function getallApi() {
			gethome().then((data) => {
				console.log(data);
				getdata(data);
			});
		}
		//   abc=gethome().then(data=>{
		//   console.log(data)
		//  })

		//getdata(abc);
		getallApi();
	}, []);

	return (
		<>
			{isAuthenticated() &&
			isAuthenticated().user._id &&
			isAuthenticated().token ? (
				<LoggedInNavbar />
			) : (
				<Header />
			)}
			<h2
				className="container"
				style={{
					color: "#E34607",
					lineHeight: "34px",
					padding: "15px",
				}}>
				{" "}
				Satsang of the day <br />
				<br />
				आजका सत्संग{" "}
			</h2>

			<div className="container">
				<div className="row">
					<div className="col-sm-6" style={{ margin: "18px auto" }}>
						<CardData
							style={cardStyle}
							mediaName="Image"
							src={receive[0]?.Image?.productPathCDN}
							src1={receive[0]?.Image?.productPath}
							description={falsyToemptyString(
								receive[0]?.Image?.description
							)}
						/>
					</div>

					<div className="col-sm-6" style={{ margin: "18px auto" }}>
						<CardData
							style={cardStyle}
							mediaName="Speech"
							src={receive[0]?.Speech?.productPathCDN}
							src1={receive[0]?.Speech?.productPath}
							description={falsyToemptyString(
								receive[0]?.Speech?.productName
							)}
						/>
					</div>
				</div>
				<br />
				<div className="row">
					<div className="col-sm-6" style={{ margin: "18px auto" }}>
						<CardData
							style={cardStyle}
							mediaName="Songs"
							src={receive[0]?.Songs?.productPathCDN}
							src1={receive[0]?.Songs?.productPath}
							description={falsyToemptyString(
								receive[0]?.Songs?.productName
							)}
						/>
					</div>

					<div className="col-sm-6" style={{ margin: "18px auto" }}>
						<CardData
							style={cardStyle}
							mediaName="Vedio"
							src={receive[0]?.Vedio?.productPathCDN}
							src1={receive[0]?.Vedio?.productPath}
							description={falsyToemptyString(
								receive[0]?.Vedio?.productName
							)}
						/>
					</div>
				</div>
			</div>

			<center>
				<br />
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<Button
							onClick={() => {
								navigator.clipboard.writeText(
									"https://harikrishnamandir.org/mainpage"
								);
								alert("Link of this page is copied!");
								return true;
							}}
							variant="outlined"
							style={{
								borderRadius: "18px",
								color: "#E34607",
								backgroundColor: "#FFFBF4",
								borderColor: "White",
								border: "1px solid #E34607",
							}}>
							SHARE LINK
						</Button>{" "}
					</Grid>
					<Grid item xs={6}>
						<Link to="/">
							{" "}
							<Button
								variant="outlined"
								style={{
									borderRadius: "18px",
									color: "#E34607",
									borderColor: "#FFFBF4",
									backgroundColor: "#FFFBF4",
									border: "1px solid #E34607",
								}}>
								VISIT SITE
							</Button>
						</Link>
					</Grid>
				</Grid>
			</center>
			<br />

			<Footer />
		</>
	);
};

export default Home;
