import { useEffect,useState } from "react";
import { Document, Page } from 'react-pdf';
import LoggedInNavbar from "./loggedInNavbar";
import { Link } from "react-router-dom";


const DisplayDocument=(props)=>{
const [prdtdt,setprdt]=useState("");

useEffect(()=>{

    let params = new URLSearchParams(document.location.search);
    let  urrl=params.get("id")
    setprdt(urrl)


},[])

return(
  <>
  <LoggedInNavbar />
  <br/>
 <div className="container">
  <div className="row">
   
      <div className="col-sm-12">
      
        <center> 
    
        <br/>
        <iframe src={prdtdt} type="application/pdf"/><br/>
        <Link to='/document'><button className="btn btn-success">Go back</button></Link>
        </center>
 
  </div>
  </div>
  </div>

  </>
)

}

export default DisplayDocument;