import Header from "./Header";
import MainPageImage from "./MainPage.png";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CardData from "./IndexComponent/CardItem";
import Footer from "./Footer";
import { isAuthenticated } from "../../Auth/helper";
import smallimg from "../../mainImage2.jpg";
import LoggedInNavbar from "../../Admin/helper/loggedInNavbar";
import "../../body.css";
import Radium, { StyleRoot } from "radium";
import { mainpageData } from "./getapi";

const MainPage = () => {
	const des = `Hari Krishna Mandir was built in 1959 with the blessings of the Lord and His divine minstrel Mirabai, by two God realized “pilgrims of the stars” namely Dadaji Sri Dilip Kumar Roy (1897-1980) and his daughter disciple, Ma Indira Devi (1920 - 1997)
    Lord Krishna and Radharani are the presiding deities of this Ashram, which was built with unstinting love and support of like minded devotees seeking spiritual enlightenment.
    Dadaji was one of the foremost disciples of Sri Aurobindo and spent over 23 years at the Ashram in Pondicherry until Sri Aurobindo s Mahasamadhiin 1951.
    His luminous personality is reflected through his astonishingly vast creative output of soul stirring music, prose, poetry, drama, compositions and correspondence in Bengali and English .
    Ma Indira Devi while in her late twenties, was uprooted from her worldly moorings by the storm of His love. It was under the aegis of her Guru Dadaji, that Ma flowered spiritually.
    Over a period of 30 years, Ma received about 800 Mirabhajans which were set to tune by Dadaji who sang them in the temple hall in his divine voice, day after rapturous day, while Ma danced in ecstasy.
    `;
	const des1 = `The purpose of an ashram in Ma’s words,”Worldly life revolves around our own egos. It is difficult to focus one’s thoughts, actions and love on the Unseen. In an Ashram the Guru makes this possible for here, everything is centred around him.
    Disciples are bound by the common bond of Guru Bhakti and aspiration. Service and love become possible. Dadaji and Ma set an example of how one must follow the lead of Satguru with the goal of aspiration (for the Lord), rejection (of vanity and ego and surrender.
    Drawn by the simplicity, warmth and love of Dadaji and Ma, seekers come to re kindle their spirit. Devotees come together to offer a daily evening Satsang in the temple hall, which is open to all. 
    `;
	const [dess, setdes] = useState(des);

	const goButton = document.querySelector("#vanish");

	const loadmore = () => {
		setdes(des + des1);
		goButton.style.display = "none";
	};

	const style1 = {
		"@media (min-width: 667px)": {
			backgroundImage: `url(${smallimg})`,
		},
	};

	return (
		<>
			{isAuthenticated() &&
			isAuthenticated().user._id &&
			isAuthenticated().token ? (
				<LoggedInNavbar />
			) : (
				<Header />
			)}
			<StyleRoot>
				<div
					className="contain"
					style={{
						...style1,
						backgroundColor: "lightblue",
						minHeight: "100vh",
						display: "flex",
						justifyContent: "center",
						alignItems: "flex-end",
						paddingBottom: "120px",
						backgroundImage: `url(${MainPageImage})`,
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
					}}>
					<center>
						<Link to="/mainpage">
							<Button
								className="button__cta"
								variant="outlined"
								style={{
									padding: "16px 18px",
									outline: "none",
									borderRadius: "9px",
									backgroundColor: "rgba(227, 70, 7, 0.5)",
									color: "White",
									border: "3px solid white",
									backdropFilter: "blur(10px)",
									boxShadow:
										"rgba(255, 255, 255, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.25) 0px 10px 10px",
								}}>
								<b>
									Satsang of the day <br />
									आजका सत्संग{" "}
								</b>
							</Button>{" "}
						</Link>
					</center>
				</div>
			</StyleRoot>
			<br />
			<div className="container">
				<h2
					style={{
						color: "#E34607",
						lineHeight: "normal",
						padding: "20px",
					}}>
					Hari Krishna Mandir, Pune
				</h2>

				<div className="container">
					<div className="row">
						<div className="col-sm-12 mb-3">
							<CardData
								mediaName="content"
								description={des + des1}
								clicked={loadmore}
							/>
						</div>
					</div>

					<h2
						style={{
							color: "#E34607",
							lineHeight: "34px",
							padding: "20px",
						}}>
						Content
					</h2>

					<center>
						<div className="row">
							<div className="col-sm-6 d-flex mb-3">
								<CardData
									mediaName="content1"
									description="The HKM photo gallery contains photos spanning the period from 1940 till today. The content have been placed in albums based on subject/topic such as Dadaji,Ma with disciples, Ma in Bhaav etc The older photos have been restored using AI enabled tools. "
								/>
							</div>

							<div className="col-sm-6 d-flex mb-3">
								<CardData
									mediaName="content2"
									description="Over the years, many close disciples have recorded talks in various recorders of that time. These include celebrations, candid talks, translations by Ma of Tulsi Ramayana, Guru Granth Sahib, RamkrishnaKathamrita to name a few. These have also been restored using Machine learning tools and stored in the relevant categories for easy access. Additionally, like modern online music players, last heard discourse reappears when this page is opened. Those talks already heard are marked for convenience."
								/>
							</div>
						</div>

						<div className="row">
							<div className="col-sm-6 d-flex mb-3">
								<CardData
									mediaName="content3"
									description="Ma has dictated over 800 Mirabhajans over the years which were set to tune by Dadaji and sung in the temple hall. These as well as over 100 compositions of Dadaji in Bengali are available in this folder."
								/>
							</div>

							<div className="col-sm-6 d-flex mb-3">
								<CardData
									mediaName="content5"
									description="Videos of big days at Hari Krishna mandir as well as candid moments upstairs have been restored like the talks and placed in relevant topic folders such as Narrations, Candid talks, Ramayana and so on."
								/>
							</div>
						</div>

						<div className="row">
							<div className="col-sm-6 d-flex mb-3">
								<CardData
									mediaName="content4"
									description="Dadaji was a prolific writer and has over 75 titles in Bengali and 25 in English to his credit. The English books have been uploaded as PDFs. Over time the Bengali books will also be available in the relevant folder."
								/>
							</div>
						</div>
					</center>
				</div>
				<br />
				<div
					className="contact"
					id="contact"
					style={{ backgroundColor: "#F5F5F5", padding: "0px 20px" }}>
					<br />

					<h2
						style={{
							color: "#E34607",
							lineHeight: "34px",
							padding: "20px",
						}}>
						Contact
					</h2>
					<br />

					<div className="container">
						<div className="row">
							<div className="col-sm-6">
								<iframe
									src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.9506908484677!2d73.83521711436892!3d18.53113027368889!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf7ed928f9e7%3A0xcf6bffb9d635036d!2sHari%20Krishna%20Mandir!5e0!3m2!1sen!2sin!4v1657377791760!5m2!1sen!2sin"
									style={{
										width: "100%",
										height: "500px",
									}}></iframe>
							</div>
							<div className="col-sm-6">
								<center style={{ padding: "0px 30px" }}>
									<br />
									<br />
									<span style={{ textAlign: "left" }}>
										<h4
											style={{
												color: "#E34607",
												lineHeight: "24px",
											}}>
											<i
												className="fa fa-map-marker"
												aria-hidden="true"></i>{" "}
											Postal Address
										</h4>

										<h5 style={{ fontSize: "14px" }}>
											Hari Krishna Mandir Trust,
										</h5>
										<h5 style={{ fontSize: "14px" }}>
											'Indira Niloy' Hari Krishna Mandir
											Road
										</h5>
										<h5 style={{ fontSize: "14px" }}>
											Model Colony, ShivajiNagar
										</h5>
										<h5 style={{ fontSize: "14px" }}>
											Pune, Maharashtra, India - 411016
										</h5>

										<br />
										<br />
										<h4
											style={{
												color: "#E34607",
												lineHeight: "24px",
											}}>
											<i
												className="fa fa-envelope"
												aria-hidden="true"></i>{" "}
											Email Address
										</h4>
										<h5 style={{ fontSize: "14px" }}>
											harikrishnamandir@gmail.com
										</h5>
										<h5 style={{ fontSize: "14px" }}>
											badesai1@gmail.com
										</h5>
										<br />
										<br />
										<h4
											style={{
												color: "#E34607",
												lineHeight: "24px",
											}}>
											<i
												className="fa fa-phone"
												aria-hidden="true"></i>{" "}
											Telephone numbers
										</h4>
										<h5 style={{ fontSize: "14px" }}>
											+91 9960610575
										</h5>
										<h5 style={{ fontSize: "14px" }}>
											+91 9890802596
										</h5>
									</span>
								</center>
							</div>
						</div>

						<br />
						<br />

						<br />
					</div>
				</div>
			</div>
			<a
				href="https://wa.me/919960610575"
				target="_blank"
				rel="noreferrer">
				<span
					style={{
						position: "fixed",
						right: "20px",
						bottom: "80px",
						fontSize: "45px",
						color: "green",
						cursor: "pointer",
						width: "50px",
						height: "50px",
						backgroundColor: "#f5f5f5",
						display: "flex",
						justifyContent: "center",
						alignContent: "center",
						borderRadius: "100%",
					}}>
					<i
						className="fa fa-whatsapp
"></i>
				</span>
			</a>

			<Footer />
		</>
	);
};

export default MainPage;
