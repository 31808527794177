import { useEffect, useState } from "react";
import { getAllDocuments } from ".";
import LoggedInNavbar from "./loggedInNavbar";
import { isAuthenticated } from "../../Auth/helper";
import { useNavigate } from "react-router-dom";

const ShowDocument = (props) => {
	const [prdtdt, setprdt] = useState([]);
	let nav = useNavigate();
	useEffect(() => {
		function fetchdata() {
			let auth = isAuthenticated();
			getAllDocuments(auth.user._id, auth.token)
				.then((data) => {
					console.log(data);
					setprdt(data);
				})
				.catch();
		}

		fetchdata();
	}, []);

	const ShowImage = (id) => {
		nav(`/displaydocument?id=${id}`);
	};

	return (
		<>
			<LoggedInNavbar />
			<div className="container">
				<br />
				<h2
					style={{
						color: "#E34607",
						lineHeight: "34px",
						padding: "20px",
					}}>
					E-Books
				</h2>
				<div className="container">
					<div className="row">
						{prdtdt.map((item) => {
							return (
								<div
									className="col-sm-3"
									style={{ marginBottom: "18px" }}>
									<div
										className="card"
										style={{
											width: "18rem",
											cursor: "pointer",
											padding: "0px",
											border: "1px solid rgba(136,136,136,0.5)",
										}}
										onClick={() => {
											alert("Click ok to begin download");
											ShowImage(item.productPathCDN);
										}}>
										<div className="card-body">
											<img
												src={item.thumbNailPathCDN}
												height="200"
												width="200"
												style={{
													marginBottom: "10px",
												}}></img>
											<br />
											<center>
												{" "}
												{/* <h5 className="card-title" style={{ color: "#E34607" }}>
                          {item.productName}
                        </h5> */}
											</center>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
};

export default ShowDocument;
