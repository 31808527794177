import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isAuthenticated } from "../../Auth/helper";
import { useParams } from "react-router-dom";
import LoggedInNavbar from "./loggedInNavbar";
import { getCategoryData } from ".";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";

const CategoryFilter = () => {
	const [data, setData] = useState([]);
	const { cat, catName } = useParams();
	const navigate = useNavigate();
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [show, setShow] = useState(false);
	const [modalitem, setmodelitem] = useState([]);
	const [val, setVal] = useState("");
	const checkSignedIn = (id, musicid, item) => {
		const isSignedin =
			isAuthenticated() &&
			isAuthenticated().token &&
			isAuthenticated().user._id;

		if (isSignedin) {
			//
			if (cat === "Videos") {
				navigate(`/player/${id}/${musicid}/?way=${item}`);
			}

			if (cat === "Images") {
				navigate(`/displaydata/${id}/${musicid}?id=${item}`);
			}
			if (cat === "Audios") {
				navigate(`/audioplayer/${id}/${musicid}/?way=${item}`);
			}
			if (cat === "Speech") {
				navigate(`/speechplayer/${id}/${musicid}/?way=${item}`);
			}
			if (cat === "Songs") {
				navigate(`/songplayer/${id}/${musicid}/?way=${item}`);
			}
		} else {
			navigate("/signin");
		}
	};

	useEffect(() => {
		let auth = isAuthenticated();
		console.log(typeof catName);
		getCategoryData(auth.user._id, auth.token, { cat, catName })
			.then((dat) => {
				setData(dat);
			})
			.catch();
	}, []);

	return (
		<>
			<div>
				<LoggedInNavbar />
				<br />
				<div
					className="container"
					style={{ width: "90%", height: "800px" }}>
					<h2
						style={{
							color: "#E34607",
							lineHeight: "normal",
							padding: "20px",
						}}>
						{cat} / {catName}
					</h2>
					<div
						className="row"
						style={{
							backgroundColor: "white",
							height: "800px",
							overflow: "scroll",
							margin: "0 9px 42px 9px",
							boxShadow:
								"rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
							border: "solid 1px rgba(149, 157, 165, 0.2)",
							paddingTop: "18px",
						}}>
						{cat !== "Images"
							? data.map((item) => {
									return (
										<div
											className="col-sm-4"
											style={{ padding: "13px" }}>
											<center>
												<div
													className="card"
													style={{
														flexDirection: "row",
														cursor: "pointer",
														backgroundColor:
															"#f5f5f5",
														height: "100px",
													}}
													onClick={() =>
														checkSignedIn(
															item._id,
															item.productCategory
																._id,
															item.productPathCDN
														)
													}>
													<LazyLoadImage
														style={{
															width: "100px",
															height: "100%",
															borderRadius:
																"5px 0 0 5px",
														}}
														src={
															item.thumbNailPathCDN
														}
														alt="gvg"
														loading="lazy"
													/>
													<div className="card-body">
														<h6 className="card-title">
															{item.productName}
														</h6>
													</div>
												</div>
											</center>
										</div>
									);
							  })
							: data.map((item) => {
									return (
										<div
											className="col-6"
											style={{
												padding: "13px",
												width: "33%",
											}}>
											<center>
												<div
													style={{
														cursor: "pointer",
													}}
													onClick={() => {
														setmodelitem(item);
														console.log(
															"this opens modal from catagory Filter"
														);
														handleShow();
													}}>
													<center>
														<LazyLoadImage
															src={
																item.productPathCDN
															}
															alt="gvg"
															loading="lazy"
															height="auto"
															width="100%"
														/>
													</center>
												</div>
											</center>
										</div>
									);
							  })}
					</div>
				</div>

				<Modal show={show} onHide={handleClose} size="xl">
					<Modal.Header closeButton>
						<Modal.Title></Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<center>
							<LazyLoadImage
								src={modalitem?.productPathCDN}
								alt="gvg"
								loading="lazy"
								height="95%"
								width="95%"
							/>
						</center>
						<center>
							<p>{modalitem?.description}</p>
						</center>
					</Modal.Body>
					<Modal.Footer></Modal.Footer>
				</Modal>
			</div>
		</>
	);
};
export default CategoryFilter;
