import { useState } from "react";
import LoggedInNavbar from "../../Admin/helper/loggedInNavbar";
import AddUser from "./userManagement/adduser";
import DeleteUser from "./userManagement/deleteUser";
import AddMedia from "./mediaManagement/addMedia";
import DeleteMedia from "./mediaManagement/deleteMedia";
import { Link } from "react-router-dom";

const AdminDashboard=()=>{
const [getaction,setaction]=useState(0)



  return(<>
  
   <LoggedInNavbar/>
   <br />
   
     <div className="row">
      
      <div className="col-sm-4">
      <div className="card w-100" style={{backgroundColor:"#191919",color:"white"}}>
  <div className="card-body">
    <h5 className="card-title">User Management</h5>
   
   <div className="row">
 <div className="col-sm-6"> <button className="btn btn-success" style={{width:"100%"}} onClick={()=>setaction(1)} >AddUser</button></div>
 <div className="col-sm-6"> <button className="btn btn-danger" style={{width:"100%"}} onClick={()=>setaction(2)}>DeleteUser</button></div> 
 </div>
 <br />

  
  </div>
</div>
</div>

  

     
      <div className="col-sm-8">

      <div className="card w-100" style={{backgroundColor:"#191919",color:"white",height:"140px"}}>
  <div className="card-body">
    <h5 className="card-title">Media Management</h5>
      
    <div className="row">
 <div className="col-sm-3"> <Link to="/admindashboard/uploadvideos"><button className="btn btn-success" style={{width:"100%"}}  >Videos</button></Link></div>
 <div className="col-sm-3"><Link to="/admindashboard/uploadspeech"> <button className="btn btn-danger" style={{width:"100%"}} >Speech</button></Link></div> 
 <div className="col-sm-3"><Link to="/admindashboard/uploadsongs"> <button className="btn btn-success" style={{width:"100%"}}  >Songs</button></Link></div>
 <div className="col-sm-3"> <Link to="/admindashboard/uploadimages"><button className="btn btn-danger" style={{width:"100%"}}>Images</button></Link></div> 
 <div className="col-sm-3"><Link to="/admindashboard/uploaddocuments"> <button className="btn btn-danger" style={{width:"100%"}}>Documents</button></Link></div> 
 </div>
    
  </div>
</div>


      </div>

      <div className="row" style={{padding:'3px',margin:'3px'}}>
 <div className="col-sm-3"> <Link to="/admindashboard/editvideos"><button className="btn btn-success" style={{width:"100%"}}  > Edit Videos</button></Link></div>
 <div className="col-sm-3"><Link to="/admindashboard/editspeech"> <button className="btn btn-danger" style={{width:"100%"}} >Edit Speech</button></Link></div> 
 <div className="col-sm-3"><Link to="/admindashboard/editsongs"> <button className="btn btn-success" style={{width:"100%"}}  > Edit Songs</button></Link></div>
 <div className="col-sm-3"> <Link to="/admindashboard/editimage"><button disabled='true' className="btn btn-danger" style={{width:"100%"}}> Edit Images</button></Link></div> 
 <div className="col-sm-3"><Link to="/admindashboard/editdocuments"> <button className="btn btn-danger" style={{width:"100%"}}> Edit Documents</button></Link></div> 
 </div>
     
      </div>

      { getaction==1 ? <AddUser /> : getaction==2 ? <DeleteUser/>:getaction==3 ? <AddMedia/>: <DeleteMedia/> }
    
      
  
  </>)

}

export default AdminDashboard;