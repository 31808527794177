import React from 'react';
import ReactDOM from 'react-dom';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import  "./body.css";
import App from './App';


ReactDOM.render(
  
    <App />
  ,
  document.getElementById('root')
);


