import React, { useEffect, useState } from "react";
import { getAllImagesWithout } from ".";
import { isAuthenticated } from "../../Auth/helper";
import { API } from "../../backend";
import LoggedInNavbar from "./loggedInNavbar";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ShowImage = () => {
	const navigate = useNavigate();
	let [products, setAllProducts] = useState([]);

	let [mainCat, setMainCat] = useState([]);
	let [catThumbNail, setCatThumbNail] = useState([]);

	const checkSignedIn = (id, musicid, item) => {
		console.log(id, musicid);
		const isSignedin =
			isAuthenticated() &&
			isAuthenticated().token &&
			isAuthenticated().user._id;
		if (isSignedin) {
			//
			navigate(`/displaydata?way=${item}`);
		} else {
			navigate("/signin");
		}
	};

	useEffect(() => {
		console.log("This is useeffect");
		const fetchdata = async () => {
			try {
				await getAllImagesWithout()
					.then((x) => {
						console.log(x);

						const getsubCategory = x.map(
							(item) => item.subCategoryId
						);
						let uniqueObjArray = [
							...new Map(
								getsubCategory.map((item) => [
									item["subCategoryName"],
									item,
								])
							).values(),
						];
						console.log(uniqueObjArray);

						const mainCategory = uniqueObjArray.map(
							(item) => item.subCategoryName
						);
						const thumbArr = uniqueObjArray.map(
							(item) => item.subCategoryImageCDN
						);

						setMainCat(mainCategory);
						setCatThumbNail(thumbArr);
						setAllProducts(x);
					})
					.catch();

				// await allActiveMusicArray(c.user._id,c.token).then((as)=>{
				//             console.log(as.Bookmark)
				//             let newBookmark=new Array();
				//           as.Bookmark.map((r,n)=>{
				//               console.log(r)
				//               if(r.CategoryName==mediatype){
				//                   console.log("helo")
				//                 newBookmark.push(r)
				//               }
				//           })
				//           console.log(newBookmark)

				//             setplayed(as.ActiveMusic)
				//             setbookmark(newBookmark.reverse())
				//           }).catch((e)=>{
				//               alert("Failed to load resource");
				//               return false;
				//           })
			} catch {}
		};

		fetchdata();
		//getActiveMusicArray()
	}, []);

	const showCategory = (id) => {
		const Video = "Images";
		navigate(`/category/${Video}/${id}`);
	};

	return (
		<div>
			<LoggedInNavbar />
			<br />
			<div className="container">
				<h2
					style={{
						color: "#E34607",
						lineHeight: "34px",
						padding: "20px",
					}}>
					Albums
				</h2>

				<center>
					<div className="container">
						<div className="row">
							{mainCat.map((item, index) => {
								return (
									<div
										className="col"
										style={{ padding: "5px" }}>
										<center>
											{" "}
											<div
												onClick={() =>
													showCategory(item)
												}
												style={{
													width: "18rem",
													cursor: "pointer",
												}}>
												<LazyLoadImage
													src={catThumbNail[index]}
													alt={item}
													height="200"
													width="200"
												/>
											</div>
										</center>
									</div>
								);
							})}
						</div>
					</div>
				</center>

				<br />
				<br />
			</div>
		</div>
	);
};

export default React.memo(ShowImage);
