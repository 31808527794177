import { useEffect, useState } from "react";
import {
  authenticate,
  isAuthenticated,
  triggerSignin,
  triggerSignup,
} from "../../Auth/helper";
import { useNavigate } from "react-router-dom";

import Header from "./Header";
const SignUp = () => {
  console.log(process.env.REACT_APP_BACKEND);

  const [formValue, setFormValue] = useState({
    Name: "",
    MobileNo: "",
    Address: "",
    EmailId: "",
    password: "",
    errorMessage: false,
  });

  let Navigate = useNavigate();
  const { MobileNo, password, errorMessage, Name, Address, EmailId } =
    formValue;

  const handleChange = (name) => (e) => {
    setFormValue({ ...formValue, [name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    console.log(formValue);
    e.preventDefault();
    setFormValue({ ...formValue, errorMessage: false });
    if (MobileNo && password && Name && Address && EmailId) {
      await triggerSignup({ MobileNo, password, Name, Address, EmailId }).then(
        (r) => {
          if (r?.error) {
            setFormValue({ ...formValue, errorMessage: r.error });
          }
          if (r?.message) {
            setFormValue({ ...formValue, errorMessage: r.message });
          }
          if (r?.success) {
            alert(r.success);
            Navigate("/signin");
          }
        }
      );
    } else {
      setFormValue({ ...formValue, errorMessage: "Enter valid credentials" });
      return false;
    }
  };

  return (
    <>
      <Header />
      <br />
      <br />

      <div className="container">
        {" "}
        <div className="row">
          <div className="col-sm-3"></div>
          <center>
            <form className="col-sm-3">
              <div className="form-group">
                <input
                  type="text"
                  name="Name"
                  onChange={handleChange("Name")}
                  placeholder="Name"
                  className="form-control"
                  style={{ borderRadius: "15px 15px 15px 15px" }}
                ></input>
              </div>
              <br />
              <div className="form-group">
                <input
                  type="number"
                  name="mobileNo"
                  onChange={handleChange("MobileNo")}
                  placeholder="MobileNo"
                  className="form-control"
                  style={{ borderRadius: "15px 15px 15px 15px" }}
                ></input>
              </div>
              <br />
              <div className="form-group">
                <input
                  type="text"
                  name="Address"
                  onChange={handleChange("Address")}
                  placeholder="Address"
                  className="form-control"
                  style={{ borderRadius: "15px 15px 15px 15px" }}
                ></input>
              </div>
              <br />

              <div className="form-group">
                <input
                  type="email"
                  name="EmailId"
                  onChange={handleChange("EmailId")}
                  placeholder="EmailId"
                  className="form-control"
                  style={{ borderRadius: "15px 15px 15px 15px" }}
                ></input>
              </div>
              <br />

              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  onChange={handleChange("password")}
                  placeholder="Create Password"
                  className="form-control"
                  style={{ borderRadius: "15px 15px 15px 15px" }}
                ></input>
              </div>
            </form>
          </center>
          <br />
          <br />
          <center>
            {" "}
            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-info"
              style={{ margin: "15px", backgroundColor: "#E34607" }}
            >
              Sign up
            </button>
          </center>

          <br />
          {errorMessage && (
            <div
              className="alert alert-warning alert-dismissible fade show"
              role="alert"
            >
              <strong>{errorMessage}</strong>
            </div>
          )}
        </div>
        <div className="col-4"></div>
      </div>
    </>
  );
};
export default SignUp;
