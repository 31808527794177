import React, { useEffect,useState } from "react";
import { allActiveMusicArray, getAllAudios,sendbookmark} from ".";
import { isAuthenticated } from "../../Auth/helper"
import { API } from "../../backend"
import LoggedInNavbar from "./loggedInNavbar";
import ReactPlayer from 'react-player/lazy';
import Header from "../../User/helper/Header";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";






const AudioPlay=()=>{
    
    const { userId,musicId } = useParams();
    console.log(userId)
    let mediatype=1;
    const navigate = useNavigate();
    let [products,setAllProducts]=useState([])
    let [playval,setplayval]=useState({
        isplay:false,
        itemid:"",
       
    });
    let [isReady,setReady]=useState(false);
  
    let [playmedia,setmedia]=useState(false);   
 let [recentlyplayed ,setplayed]=useState([])
 let[linkPlay,setLinkPlay]=useState(0);
 let [bookmarkarray,setbookmark]=useState([])

 let [showbmcolor,getbmcolor]=useState([])
 const theme = useTheme();
    const {isplay,itemid}=playval;
   
    const playerref=React.useRef();


   const showmyFav=()=>{
       console.log(bookmarkarray)
       return(<>
    {bookmarkarray.map((item,key)=>{
        console.log("This is undermap",bookmarkarray)
        return(
       <>
             <div className="col-sm-4" style={{"padding":"13px"}}>
                    <center>
                <Card sx={{ display: 'flex' }} style={{cursor:"pointer"}} onClick={()=>{ setLinkPlay(item._id)
                 setmedia(item.productPath)
        setplayval({isplay:true,itemid:item._id,user:isAuthenticated().user._id});
        setReady(false)
        
        getActiveMusicArray()
        } }>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5">
                     {item.productName}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                 
                    </Typography>
                  </CardContent>
                </Box>
                <CardMedia
                  component="img"
                  sx={{ width:300 }}
                  image={item.thumbNailPath}
                  alt="Live from space album cover"
                />
              </Card>
              </center>
              </div>
             
</>

        
      )

    })
   
}
</>)}
   


   
   
  
    const getActiveMusicArray=async()=>{

        let c=isAuthenticated();
    await allActiveMusicArray(c.user._id,c.token).then((as)=>{
      setplayed(as.ActiveMusic)
    }).catch((e)=>{
        alert("Failed to load resource");
        return false;
    })

    }


    const checkSignedIn=(id)=>{
  
     const isSignedin= isAuthenticated() && isAuthenticated().token && isAuthenticated().user._id
      if(isSignedin)
      {
       navigate(`/player?id=${id}`)
      }
      else{
      navigate("/signin")
      }
    }


useEffect(()=>{
    console.log("This is myfav nav")
  showmyFav();
},[bookmarkarray])





    const bookmark=async(uuid)=>{
        console.log("Gello Man")
        console.log(bookmarkarray)
        let cc=isAuthenticated();
        getbmcolor([...showbmcolor,uuid])  
       if(bookmarkarray.find((x)=> x.prouctId==uuid)){
         return false
       }

      
        await sendbookmark(cc.user._id,cc.token,{"productId":uuid}).then(rep=>{
            console.log(rep)
            
            
         setbookmark([...bookmarkarray,rep])
         
        }).catch(e=>{
            console.log("error")
        })
    }






        const showMedia=(playmedia)=>{
          //  console.log( isAuthenticated().user.ActiveMusic)
          // console.log("thi is  showmedia rnder")
     

            return(
           <div>

                <ReactPlayer
                                                  style={{padding:'15px',width:'100%',height:"auto",margin:"0 auto"}}
                                                  height="auto"
                                                  width="90%"
                           ref={playerref}
                            url= {playmedia}
                            playing={true}
                            controls={true}
                     
                            config={{ file: { 
                                attributes: {
                                  controlsList: 'nodownload'  //<- this is the important bit
                                }
                            }}}
                            onReady={readyState}
                            progressInterval={1000}
                             onProgress={(r)=>{
                              
                               
                               watchedvedio(linkPlay,r.playedSeconds)
                                 
                             }

                            }

                            onEnded={()=>{
                                watchedvedio(linkPlay,0)
                            }}

                         
                            
                        />
                       
                        
                            </div>

                         
                            
                            )
                    
            
        }
    
    





    const readyState=async()=>{
console.log("rerendering")
        
      let sec=0;
    
    //  console.log(itemid)
    
let cur=isAuthenticated();

await allActiveMusicArray(cur.user._id,cur.token).then((repo)=>{
  console.log(itemid)
  
     repo.ActiveMusic.map((q)=>{
         if(q.itemid==itemid){
            sec=parseInt(q.seconds) 
            console.log("Seconds are",sec)
         }
     })

})

//    await isAuthenticated().user.ActiveMusic.map((e)=>{
               
//             if(e.itemid===itemid)
//               {
//               console.log(e.seconds)
//               sec=parseInt(e.seconds)
//               }
//           })
        
   console.log(sec)
   
        if(!isReady ){
            console.log(sec)
         
     await  playerref.current.seekTo(parseInt(sec))
            setReady(!isReady)
        }
    }


    
     


     
        

    const watchedvedio=async(item_id,playedSeconds)=>{
     
     let getvalues= await isAuthenticated();

     let newvl={"itemid":item_id,"seconds":Math.floor(playedSeconds)};
 
        return await fetch(`${API}user/savedata/${getvalues.user._id}/${item_id}`,
        {
            method:"PATCH",
            headers:{
               Accept:"application/json",
               "Content-Type":"application/json",
               Authorization: `Bearer ${getvalues.token}`  
            },

            body: JSON.stringify({"ActiveMusic":newvl})
        }
        )
     
    }

    


   



    useEffect(()=>{
        console.log("This is useeffect")
        const fetchdata=async ()=>{
        try{
        let c=  await isAuthenticated();
        const {token,user}=c;
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let foo = params.get('way')
        setmedia(foo)

        setLinkPlay(userId)
       await getAllAudios(user._id,token).then(x=>{
         console.log(x)
            setAllProducts(x)

        }).catch()



     

await allActiveMusicArray(c.user._id,c.token).then((as)=>{
            console.log(as.Bookmark)
            let newBookmark=new Array();
          as.Bookmark.map((r,n)=>{
              console.log(r)
              if(r.CategoryName==mediatype){
                  console.log("helo")
                newBookmark.push(r)
              }
          })
          console.log(newBookmark)
          

            setplayed(as.ActiveMusic)
            setbookmark(newBookmark.reverse())


          }).catch((e)=>{
              alert("Failed to load resource");
              return false;
          })
      
    }
    
    catch{

    }
 
}

fetchdata()
//getActiveMusicArray()
    },[])


    return(
        <div>
             {isAuthenticated() && isAuthenticated().user._id  && isAuthenticated().token ? <LoggedInNavbar/> : <Header /> }  
               <br/>
               <div className="container-fluid">             <center>
                   
          {showMedia(playmedia)}
      
          <h4 style={{color:'#E34607',lineHeight:"34px",padding:"20px"}}>{ products!==undefined ? products.filter(item=> item._id===linkPlay)[0]?.productName  : "Videotitle"}</h4>
          <p>{ products!==undefined ? products.filter(item=> item._id===linkPlay)[0]?.description :"Description"}</p>
          <i className="fa fa-thumbs-up"  onClick={()=>bookmark(linkPlay)} style={{fontSize:"25px",cursor:"pointer", color:showbmcolor.find((x)=>{return x==linkPlay}) || bookmarkarray.find((c)=>{return c.prouctId==linkPlay})  ? "#E34607" : "grey" }}></i>
               </center>

               <br />
        
 <div className="row">

  {products.map((item,key)=>
        {
        
            return(
        
                  <div className="col-sm-6">
                    <center>
                    <div className="card" style={{flexDirection: "row",cursor:"pointer", backgroundColor:recentlyplayed.find((c)=>{return c.itemid===item._id})  ? "#f5f5f5" : "white" }} onClick={()=>{ setLinkPlay(item._id)
                 setmedia(item.productPathCDN)
        setplayval({isplay:true,itemid:item._id,user:isAuthenticated().user._id});
        setReady(false)
        
        getActiveMusicArray()
        } }>
    <img style={{width: "30%"}} src={item.thumbNailPathCDN} className="card-img-top" alt="gvg" />
 <div className="card-body">
	    <h5 className="card-title">{item.productName}</h5>
	  </div>
	</div>


  


              </center>
              </div>
             

)
      
    }
)}

 

 </div>

 <h2 style={{color:'#E34607',lineHeight:"34px",padding:"20px"}}>Recently viewed</h2>

 <div className="row" style={{overflow:"scroll"}}>

      
     {products.filter(item=>{
        return recentlyplayed.some(i=>{
           return i.itemid===item._id
        })
    }).map(item=>{
        return(
          <div className="col-sm-4" style={{"padding":"13px"}}>
          <center>
      <Card sx={{ display: 'flex' }} style={{cursor:"pointer"}} onClick={()=> {setLinkPlay(item._id);
       setmedia(item.productPathCDN)
          setplayval({isplay:true,itemid:item._id,user:isAuthenticated().user._id});
          setReady(false)
          
          getActiveMusicArray()}
  } >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5">
           {item.productName}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
        
          </Typography>
        </CardContent>
      </Box>
      <CardMedia
        component="img"
        sx={{ width:300 }}
        image={item.thumbNailPathCDN}
        alt="Live from space album cover"
      />
    </Card>
    </center>
    </div>
        )
     })} 


      </div>
           
               </div>


            </div>
     
  





         
       
       
)}

export default React.memo(AudioPlay);
