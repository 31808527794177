import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
	allActiveMusicArray,
	getAllProducts,
	getAllVedios,
	sendbookmark,
} from ".";

import { isAuthenticated } from "../../Auth/helper";
import { API, DOMAINNAME } from "../../backend";
import LoggedInNavbar from "./loggedInNavbar";
import ReactPlayer from "react-player/lazy";
import { useTheme } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Accordion from "react-bootstrap/Accordion";
import "./player.css";

const MediaPlay = () => {
	const { userId, musicId } = useParams();
	console.log(userId);
	let mediatype = 1;
	const navigate = useNavigate();
	let [products, setAllProducts] = useState([]);
	let [playval, setplayval] = useState({
		isplay: false,
		itemid: "",
	});
	let [isReady, setReady] = useState(false);
	let [playmedia, setmedia] = useState(false);
	let [recentlyplayed, setplayed] = useState([]);
	let [linkPlay, setLinkPlay] = useState(0);
	let [bookmarkarray, setbookmark] = useState([]);
	let [recommended, setRecommended] = useState([]);
	let [showbmcolor, getbmcolor] = useState([]);
	let [share, setshare] = useState([]);
	let [mainCat, setMainCat] = useState([]);
	let [catThumbNail, setCatThumbNail] = useState([]);
	let [val, setVal] = useState("");
	const theme = useTheme();
	const { isplay, itemid } = playval;

	const playerref = React.useRef();

	const change = (event) => {
		setVal(event.target.value);
	};

	// Logic for current catagory

	for (let i = 0; i < products.length; i++) {
		if (Object.values(products[i]).includes(linkPlay)) {
			var linkPlayItemCat =
				products[i]["productCategory"]["categoryName"];
			var linkvalItemSubCatagory =
				products[i]["subCategoryId"]["subCategoryName"];
		}
	}

	// let isAuthenticWorking = isAuthenticated().user.ActiveMusic;

	let abcdef = products.filter((item) => {
		if (
			item.productCategory.categoryName === linkPlayItemCat &&
			item.subCategoryId.subCategoryName === linkvalItemSubCatagory
		) {
			return {
				...item,
			};
		}
	});

	// Logic for current catagory ends here

	// Logic for filter begins below

	//

	const filteredProducts = products.filter((product) => {
		return product.productName.toLowerCase().includes(val.toLowerCase());
	});

	console.log("127", filteredProducts);

	//

	// logic for filter ends here

	//

	const showmyFav = () => {
		console.log(bookmarkarray);
		return (
			<>
				{bookmarkarray.map((item, key) => {
					console.log("This is undermap", bookmarkarray);
					return (
						<>
							<span
								style={{
									cursor: "pointer",
									fontSize: "20px",
									height: "50px",
								}}>
								<div
									className=" card w-75"
									style={{
										backgroundColor: "#05445E",
										color: "white",
										cursor: "pointer",
									}}
									onClick={() => {
										console.log(item._id);
										setplayval({
											isplay: true,
											itemid: item.prouctId,
											user: isAuthenticated().user._id,
										});
										setReady(false);

										getActiveMusicArray();
									}}>
									<div className="card-body">
										<h6 className="card-title">
											{" "}
											<i className="fa fa-play-circle"></i>
											{item.productName}
										</h6>
										<p className="card-text"> </p>
									</div>
								</div>
							</span>
							<br />
						</>
					);
				})}
			</>
		);
	};

	const getActiveMusicArray = async () => {
		let c = isAuthenticated();
		await allActiveMusicArray(c.user._id, c.token)
			.then((as) => {
				setplayed(as.ActiveMusic);
			})
			.catch((e) => {
				alert("Failed to load resource");
				return false;
			});
	};

	const checkSignedIn = (id) => {
		const isSignedin =
			isAuthenticated() &&
			isAuthenticated().token &&
			isAuthenticated().user._id;
		if (isSignedin) {
			navigate(`/player?id=${id}`);
		} else {
			navigate("/signin");
		}
	};

	useEffect(() => {
		console.log("This is myfav nav");
		showmyFav();
	}, [bookmarkarray]);

	const bookmark = async (uuid) => {
		console.log("Gello Man");
		console.log(bookmarkarray);
		let cc = isAuthenticated();
		getbmcolor([...showbmcolor, uuid]);
		if (bookmarkarray.find((x) => x.prouctId == uuid)) {
			return false;
		}

		await sendbookmark(cc.user._id, cc.token, { productId: uuid })
			.then((rep) => {
				console.log(rep);

				setbookmark([...bookmarkarray, rep]);
			})
			.catch((e) => {
				console.log("error");
			});
	};

	const showMedia = () => {
		return (
			<div>
				<ReactPlayer
					style={{
						width: "100%",
						height: "auto",
						margin: "0 auto",
					}}
					height="auto"
					width="90%"
					ref={playerref}
					url={playmedia}
					playing={true}
					controls={true}
					config={{
						file: {
							attributes: {
								controlsList: "nodownload", //<- this is the important bit
							},
						},
					}}
					onReady={readyState}
					progressInterval={1000}
					onProgress={(r) => {
						watchedvedio(linkPlay, r.playedSeconds);
					}}
					onEnded={() => {
						watchedvedio(linkPlay, 0);
					}}
				/>
			</div>
		);
	};

	const readyState = async () => {
		console.log("rerendering");

		let sec = 0;

		//  console.log(itemid)

		let cur = isAuthenticated();

		await allActiveMusicArray(cur.user._id, cur.token).then((repo) => {
			console.log(itemid);

			repo.ActiveMusic.map((q) => {
				if (q.itemid == itemid) {
					sec = parseInt(q.seconds);
					console.log("Seconds are", sec);
				}
			});
		});

		//    await isAuthenticated().user.ActiveMusic.map((e)=>{

		//             if(e.itemid===itemid)
		//               {
		//               console.log(e.seconds)
		//               sec=parseInt(e.seconds)
		//               }
		//           })

		console.log(sec);

		if (!isReady) {
			console.log(sec);

			await playerref.current.seekTo(parseInt(sec));
			setReady(!isReady);
		}
	};

	const watchedvedio = async (item_id, playedSeconds) => {
		let getvalues = await isAuthenticated();

		let newvl = { itemid: item_id, seconds: Math.floor(playedSeconds) };

		return await fetch(
			`${API}user/savedata/${getvalues.user._id}/${item_id}`,
			{
				method: "PATCH",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${getvalues.token}`,
				},

				body: JSON.stringify({ ActiveMusic: newvl }),
			}
		);
	};

	useEffect(() => {
		console.log("This is useeffect");
		const fetchdata = async () => {
			try {
				let c = await isAuthenticated();
				const { token, user } = c;

				let search = window.location.search;
				let params = new URLSearchParams(search);
				let foo = params.get("way");
				setmedia(foo);
				setplayval({ isplay: true, itemid: userId });
				setLinkPlay(userId);
				await getAllVedios(user._id, token)
					.then((x) => {
						console.log(x);
						setAllProducts(x);
					})
					.catch();

				await allActiveMusicArray(c.user._id, c.token)
					.then((as) => {
						console.log(as.Bookmark);
						let newBookmark = new Array();
						as.Bookmark.map((r, n) => {
							console.log(r);
							if (r.CategoryName == mediatype) {
								console.log("helo");
								newBookmark.push(r);
							}
						});
						console.log(newBookmark);

						setplayed(as.ActiveMusic);
						setbookmark(newBookmark.reverse());
					})
					.catch((e) => {
						alert("Failed to load resource");
						return false;
					});
			} catch {}
		};

		fetchdata();
		//getActiveMusicArray()
	}, []);

	// this is video plyer
	return (
		<div>
			<LoggedInNavbar />
			<br />
			<div className="container">
				<center>
					{showMedia(playmedia)}

					<div
						className="NavigationPanel"
						style={{
							marginTop: "1.75rem",
						}}>
						{/* Liked Button  */}

						<i
							className="icon fa fa-thumbs-up"
							onClick={() => bookmark(linkPlay)}
							style={{
								color:
									showbmcolor.find((x) => {
										return x == linkPlay;
									}) ||
									bookmarkarray.find((c) => {
										return c.prouctId == linkPlay;
									})
										? "#E34607"
										: "grey",
							}}></i>

						{/* Download Button */}

						<a href={playmedia}>
							<i
								className="fa fa-download icon"
								target="_blank"></i>
							{""}
						</a>

						{/* Next button */}

						<i
							className="fas fa-step-forward icon"
							onClick={() => {
								console.clear();

								let currnt = abcdef.filter((item, key) => {
									return item._id === linkPlay;
								});

								let next =
									abcdef[abcdef.indexOf(currnt[0]) + 1];

								if (
									abcdef.indexOf(currnt[0]) + 1 ===
										abcdef.length ||
									abcdef.indexOf(currnt[0]) + 1 >
										abcdef.length
								) {
									next = abcdef[0];
								}

								console.log(next);
								setLinkPlay(next._id);
								setmedia(next.productPath);
								setplayval({
									isplay: true,
									itemid: next._id,
									user: isAuthenticated().user._id,
								});
								setReady(false);
								setCatThumbNail(next.thumbNailPath);
								getActiveMusicArray();
								alert(`Now Playing ${next.productName}`);
							}}></i>

						{/* prev button */}
						<i
							className="fas fa-step-backward icon"
							onClick={() => {
								console.clear();

								let currnt = abcdef.filter((item, key) => {
									return item._id === linkPlay;
								});

								let next =
									abcdef[abcdef.indexOf(currnt[0]) - 1];

								if (
									abcdef.indexOf(currnt[0]) === 0 ||
									abcdef.indexOf(currnt[0]) < 0
								) {
									next = abcdef[abcdef.length - 1];
								}

								console.log(next);
								setLinkPlay(next._id);
								setmedia(next.productPath);
								setplayval({
									isplay: true,
									itemid: next._id,
									user: isAuthenticated().user._id,
								});
								setReady(false);
								setCatThumbNail(next.thumbNailPath);
								getActiveMusicArray();
								alert(`About to play ${next.productName}`);
							}}></i>

						{/* Share button */}
						<i
							className="fas fa-share-alt icon"
							onClick={() => {
								let incomming =
									products !== undefined &&
									products.filter(
										(item) => item._id === linkPlay
									)[0];
								navigator.clipboard.writeText(
									`https://harikrishnamandir.org/speechplayer/${incomming?._id}/${incomming?.productCategory?._id}?way=${playmedia}`
								);
								alert("Link copied to clipboard");
							}}></i>
					</div>
					<h2>
						{products !== undefined
							? products.filter(
									(item) => item._id === linkPlay
							  )[0]?.productName
							: "Videotitle"}
					</h2>
					<p>
						{products !== undefined
							? products.filter(
									(item) => item._id === linkPlay
							  )[0]?.description
							: "Description"}
					</p>
				</center>

				<br />
				<Accordion
					defaultActiveKey="3"
					style={{
						marginBottom: "3rem",
					}}>
					<Accordion.Item eventKey="0">
						<Accordion.Header>
							<h4>
								{linkvalItemSubCatagory} &nbsp;
								<span className="number">
									[&nbsp;{abcdef.length}&nbsp;]
								</span>
							</h4>
						</Accordion.Header>
						<Accordion.Body>
							<div className="row accordian__content">
								{abcdef.map((item, key) => {
									return (
										<div
											className="col-sm-4"
											style={{ padding: "13px" }}>
											{/* container of each card */}
											<center
												style={{
													backgroundColor: "white",
												}}>
												<div
													className="card"
													style={{
														flexDirection: "row",
														cursor: "pointer",
														backgroundColor:
															isAuthenticated().user.ActiveMusic.some(
																(q) => {
																	if (
																		q.itemid ===
																		item._id
																	) {
																		return true;
																	}
																}
															) ||
															recentlyplayed.some(
																(w) => {
																	if (
																		w.itemid ==
																		item._id
																	) {
																		return true;
																	}
																}
															)
																? "#EAEAEA"
																: "#F5F5F5",
														color: "black",
														height: "100px",
													}}
													onClick={() => {
														setLinkPlay(item._id);
														setmedia(
															item.productPath
														);
														setplayval({
															isplay: true,
															itemid: item._id,
															user: isAuthenticated()
																.user._id,
														});
														setReady(false);

														setCatThumbNail(
															item.thumbNailPath
														);
														getActiveMusicArray();
													}}>
													<LazyLoadImage
														style={{
															width: "100px",
															height: "100%",
															borderRadius:
																"5px 0 0 5px",
														}}
														src={
															item.thumbNailPathCDN
														}
														alt="gvg"
														loading="lazy"
													/>
													<div className="card-body">
														<h6
															className="card-title"
															style={{
																fontSize:
																	"14px",
															}}>
															{item.productName}
														</h6>
													</div>
												</div>
											</center>
										</div>
									);
								})}
							</div>
						</Accordion.Body>
					</Accordion.Item>

					<Accordion.Item eventKey="1">
						<Accordion.Header>
							<h4>
								All &nbsp;
								<span className="number">
									[&nbsp;{products.length}&nbsp;]
								</span>
							</h4>
						</Accordion.Header>
						<Accordion.Body>
							{
								<div
									style={{
										marginBottom: "1rem",
									}}>
									<input
										className="accordian__search"
										onChange={change}
										value={val}
										type="search"
										placeholder="Search here | यहाँ टाइप करें"
									/>
								</div>
							}
							<div className="row accordian__content">
								{filteredProducts.map((item, key) => {
									return (
										<div
											className="col-sm-4"
											style={{ padding: "13px" }}>
											<center>
												<div
													className="card"
													style={{
														flexDirection: "row",
														cursor: "pointer",
														backgroundColor:
															isAuthenticated().user.ActiveMusic.some(
																(q) => {
																	if (
																		q.itemid ===
																		item._id
																	) {
																		return true;
																	}
																}
															) ||
															recentlyplayed.some(
																(w) => {
																	if (
																		w.itemid ==
																		item._id
																	) {
																		return true;
																	}
																}
															)
																? "#EAEAEA"
																: "#F5F5F5",
														color: "black",
														height: "100px",
													}}
													onClick={() => {
														setLinkPlay(item._id);
														setmedia(
															item.productPathCDN
														);
														setplayval({
															isplay: true,
															itemid: item._id,
															user: isAuthenticated()
																.user._id,
														});
														setReady(false);
														setCatThumbNail(
															item.thumbNailPathCDN
														);
														getActiveMusicArray();
													}}>
													<LazyLoadImage
														style={{
															width: "100px",
															height: "100%",
															borderRadius:
																"5px 0 0 5px",
														}}
														src={
															item.thumbNailPathCDN
														}
														alt="gvg"
														loading="lazy"
													/>
													<div className="card-body">
														<h6
															className="card-title"
															style={{
																fontSize:
																	"14px",
															}}>
															{item.productName}
														</h6>
													</div>
												</div>
											</center>
										</div>
									);
								})}
							</div>
						</Accordion.Body>
					</Accordion.Item>

					<Accordion.Item eventKey="2">
						<Accordion.Header>
							<h4>Recently viewed</h4>
						</Accordion.Header>
						<Accordion.Body>
							<div
								className="row"
								style={{
									backgroundColor: "white",
									height: "400px",
									overflow: "scroll",
									paddingTop: "18px",
								}}>
								{products
									.filter((item) => {
										return recentlyplayed.some((i) => {
											return i.itemid === item._id;
										});
									})
									.map((item) => {
										return (
											<div
												className="col-sm-4"
												style={{ padding: "13px" }}>
												<center>
													<div
														className="card"
														style={{
															flexDirection:
																"row",
															cursor: "pointer",
															backgroundColor:
																isAuthenticated().user.ActiveMusic.some(
																	(q) => {
																		if (
																			q.itemid ===
																			item._id
																		) {
																			return true;
																		}
																	}
																) ||
																recentlyplayed.some(
																	(w) => {
																		if (
																			w.itemid ==
																			item._id
																		) {
																			return true;
																		}
																	}
																)
																	? "#EAEAEA"
																	: "#F5F5F5",
															color: "black",
															height: "100px",
														}}
														onClick={() => {
															setLinkPlay(
																item._id
															);
															setmedia(
																item.productPathCDN
															);
															setplayval({
																isplay: true,
																itemid: item._id,
																user: isAuthenticated()
																	.user._id,
															});
															setReady(false);
															setCatThumbNail(
																item.thumbNailPathCDN
															);
															getActiveMusicArray();
														}}>
														<LazyLoadImage
															style={{
																width: "100px",
																height: "100%",
																borderRadius:
																	"5px 0 0 5px",
															}}
															src={
																item.thumbNailPathCDN
															}
															alt="gvg"
															loading="lazy"
														/>
														<div className="card-body">
															<h6 className="card-title">
																{
																	item.productName
																}
															</h6>
														</div>
													</div>
												</center>
											</div>
										);
									})}
							</div>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</div>
		</div>
	);
};

export default React.memo(MediaPlay);

// let catagoryArrayNew = [];
// console.log(isAuthenticated().user.ActiveMusic);

// isAuthenticWorking?.map((item) => {
// 	products.map((i) => {
// 		if (item.itemid === i._id) {
// 			console.log("code");
// 			catagoryArrayNew.push(i);
// 		}
// 	});
// });
