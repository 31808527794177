import { Link } from "react-router-dom";
import { MDBFooter } from "mdb-react-ui-kit";
import Logo from "./logo.png";
const Footer = () => {
	return (
		<>
			<MDBFooter
				bgColor="light"
				className="text-center text-lg-start text-muted">
				<section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom"></section>

				<section className="">
					<div className="container text-center text-md-start mt-5">
						<div className="row mt-3">
							<div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
								<h6
									className=" fw-bold mb-4"
									style={{ color: "#E34607" }}>
									Hari Krishna Mandir, Pune
								</h6>
								<p></p>
							</div>

							<div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
								<h6
									className=" fw-bold mb-4"
									style={{ color: "#E34607" }}>
									Maa and her teachings
								</h6>
								<p>
									<Link to="/images">
										{" "}
										<a className="text-reset">
											<span style={{ color: "black" }}>
												Photo Gallery फोटो गैलरी{" "}
											</span>
										</a>
									</Link>
								</p>
								<p>
									<Link to="/vedios">
										{" "}
										<a className="text-reset">
											<span style={{ color: "black" }}>
												{" "}
												Videos विडियोज{" "}
											</span>
										</a>
									</Link>
								</p>
								<p>
									<Link to="/songs">
										{" "}
										<a className="text-reset">
											<span style={{ color: "black" }}>
												Songs भजन{" "}
											</span>
										</a>
									</Link>
								</p>
								<p>
									<Link to="/speech">
										{" "}
										<a className="text-reset">
											<span style={{ color: "black" }}>
												Speech प्रवचन{" "}
											</span>
										</a>
									</Link>
								</p>
								<p>
									<Link to="/document">
										{" "}
										<a className="text-reset">
											<span style={{ color: "black" }}>
												{" "}
												Books पुस्तकें{" "}
											</span>
										</a>
									</Link>
								</p>
							</div>

							<div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
								<h6
									className=" fw-bold mb-4"
									style={{ color: "#E34607" }}>
									Hari Krishna Mandir Publications
								</h6>
								<p>
									<Link to="/hkm-books">
										{" "}
										<a className="text-reset">
											<span style={{ color: "black" }}>
												{" "}
												Introduction{" "}
											</span>
										</a>
									</Link>
								</p>
								<p>
									<Link to="/wip">
										{" "}
										<a className="text-reset">
											<span style={{ color: "black" }}>
												{" "}
												About Us{" "}
											</span>{" "}
										</a>
									</Link>
								</p>
								<p>
									<Link to="/wip">
										{" "}
										<a className="text-reset">
											<span style={{ color: "black" }}>
												{" "}
												Contact
											</span>
										</a>
									</Link>
								</p>
								<p>
									<Link to="/wip">
										{" "}
										<a className="text-reset">
											<span style={{ color: "black" }}>
												{" "}
												Privacy Policy
											</span>
										</a>
									</Link>
								</p>
								<Link to="/">
									{" "}
									<img
										src={Logo}
										style={{ float: "right" }}></img>
								</Link>
							</div>
						</div>
					</div>
				</section>
			</MDBFooter>
		</>
	);
};

export default Footer;
