import { useEffect, useState } from "react";
import { getAllCategory } from "../../../../Admin/helper";
import LoggedInNavbar from "../../../../Admin/helper/loggedInNavbar";
import { isAuthenticated } from "../../../../Auth/helper";
import { uploadmediafile } from "../../../index";




const AddSongs=()=>{
  let [loading, setLoading] = useState(false);
    const [uploadmedia, setupload]=useState({
       
        productName:"",
        
        categoryId:"0",
        subCategoryId:'0',
        description:null
    })
    
    const [subCatgory,setSubCategory]=useState([])
    const [uploadafile,setuploadfile]=useState(null)
    const [uploadThumbNailfile,setuploadThumbNailfile]=useState(null)

    const {productName,categoryId, description,subCategoryId}=uploadmedia

    const [getallcat,setallcat]=useState([])

   useEffect(()=>{
let  currentdetails=isAuthenticated();

const  getcat=async()=>{
  
    await getAllCategory(currentdetails.user._id,currentdetails.token).then((re)=>{
      console.log(re)
      setallcat(re.allCategory)
      setSubCategory(re.allsubCategory)
    }).catch((e)=>{
        console.log(e);
    })

}

getcat();


   },[])


   function changeState(){
    setLoading(!loading)
    console.log(loading)
    setupload({uploadmedia:"",productName:"",categoryId:""})
    setuploadfile(0)
   }


   const handlesubmit=async(e)=>{
       e.preventDefault();
   
       if(!productName || !categoryId  || !subCategoryId || !uploadmedia){
           alert("All fields are mandatory");
           return false
       }
       else
       {
        setLoading(!loading)
           var newform=new FormData();

          

        newform.append("myfile",uploadafile);
        newform.append("myfile1",uploadThumbNailfile || null);
        newform.append("productName",productName);
        newform.append("categoryId",categoryId)
        newform.append("subCategoryId",subCategoryId)
        newform.append("description",description|| null)
         
         //let abc={"productName":productName,"categoryId":categoryId}
let cur=isAuthenticated();

 await uploadmediafile(cur.user._id,cur.token,categoryId,newform).then((ret)=>{
 if(ret.message){
setLoading(false)
  alert(ret.message);
 setupload({productName:"",
        
        categoryId:"0",
        subCategoryId:'0',
        description:""

 })
 setuploadfile(null)
 setuploadThumbNailfile(null)
}
 if(ret.error){
  setLoading(!loading)
   alert("Error in processing request");
   return false;
 }
 }).catch((e)=>{
  setLoading(!loading)
   alert("Error in sending request");
   return false
 })


       }

   }

  

   const handleformfield=name=>e=>{
   
       console.log(name,e.target.value)
       setupload({...uploadmedia,[name]:e.target.value})
   }

return(<>

<LoggedInNavbar/>
<div className="container"style={{color:"black"}}>
   <br/> <br />
   <center>
   <h5>Upload Songs</h5>
   </center>
  <hr />

    <form onSubmit={handlesubmit} >

    <div className="form-group">
    <label for="exampleFormControlInput1">Select file</label>
    <input type="file" className="form-control" name="myfile" onChange={(e)=>setuploadfile(e.target.files[0])}  id="exampleFormControlInput1" placeholder="Select file"/>
  </div>
  <div className="form-group">
    <label for="exampleFormControlInput2">Enter Media Name</label>
    <input type="text" className="form-control" name="productName"value={productName} onChange={handleformfield("productName")}  id="exampleFormControlInput2" placeholder="Media Name"/>
  </div>
  <div className="form-group">
    <label for="exampleFormControlInput3"></label>
    <select className="form-select" aria-label="Default select example" value={categoryId} onChange={handleformfield("categoryId")}>
        
 <option value="1">Select Media type</option>
  {getallcat.map((i,key)=>{
      return(<>
      
      <option key={i._id} value={i._id}>{i.categoryName}</option>
      </>)
  })}
</select>
  </div>


  <div className="form-group">
    <label for="exampleFormControlInput4"></label>
    <select className="form-select" aria-label="Default select example" value={subCategoryId} onChange={handleformfield("subCategoryId")}>
        
 <option value="1">Select Media Sub type</option>
  {subCatgory.map((i,key)=>{
      return(<>
      
      <option key={i._id} value={i._id}>{i.subCategoryName}</option>
      </>)
  })}
</select>
  </div>

  <div className="form-group">
    <label for="exampleFormControlInput1">Select ThumbNail</label>
    <input type="file" className="form-control" name="myfile1" accept="image/*" onChange={(e)=>setuploadThumbNailfile(e.target.files[0])}  id="exampleFormControlInput1" placeholder="Select file"/>
  </div>

  <div className="form-group">
    <label for="exampleFormControlInput2">Description</label>
  <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"  value={description} name="description" onChange={handleformfield("description")}></textarea>
  </div> 

  

<br />
  <center>
 <button className="btn btn-success" disabled={loading} type="submit">SUBMIT</button> 

  
  </center>
</form>




    </div>
     




</>)

}

export default AddSongs;